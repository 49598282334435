import { Background, CarouselElement } from '@/models-queries/models';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'template-carousel',
  templateUrl: './template-carousel.component.html',
  styleUrls: ['./template-carousel.component.scss']
})
export class TemplateCarouselComponent
{
    @Input() carouselTitle: string = '';
    @Input() background: Background | undefined;
    @Input() carouselElements: CarouselElement[] = [];

    @Output() onElementClicked: EventEmitter<CarouselElement> = new EventEmitter<CarouselElement>();
    
    backendUrl: string = environment.backEndUrl;

    currentElementId: number = 0;

    constructor()
    {
    }

    onPrevClick(): void
    {
        this.currentElementId--;
        if (this.currentElementId < 0)
        {
            this.currentElementId = this.carouselElements.length - 1;
        }
    }

    onNextClick(): void
    {
        this.currentElementId++;
        if (this.currentElementId > this.carouselElements.length - 1)
        {
            this.currentElementId = 0;
        }
    }

    onElementClick(elem: CarouselElement): void
    {
        this.onElementClicked.emit(elem);
    }
}
