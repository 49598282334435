
import { Chapter, SubChapter } from '@/models-queries/models';
import { Route } from '@/models-queries/route';
import { AuthenticationService } from '@/services/authentication.service';
import { ChaptersService } from '@/services/chapters.service';
import { I18nService } from '@/services/i18n.service';
import { ModalService } from '@/services/modal.service';
import { Component, OnInit, OnDestroy, ChangeDetectorRef  } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.scss']
})
export class MainComponent implements OnInit, OnDestroy 
{
    reloading: boolean = false;

    useDebugImg: boolean = false;
    useDebugColumns: boolean = false;

    useHeader: boolean = false;
    usePageMode: boolean = false;
    useHome: boolean = false;
    selectedChapter: Chapter | undefined;
    selectedSubChapter: SubChapter | undefined;

    routeChangeSubscription: Subscription | undefined;

    constructor(private cd: ChangeDetectorRef, private router: Router, private modalService: ModalService, 
                private i18nService: I18nService, private authService: AuthenticationService, private chaptersService: ChaptersService)
    {
    }

    ngOnInit(): void
    {
        // S'abonne a la route pour détecter les changements de route
        this.routeChangeSubscription = this.router.events.subscribe((val) =>
        {
            this.useHeader = this.router.url !== "/" + Route.Authentication;
            this.usePageMode = this.router.url.includes(Route.Page);
            this.useHome = this.router.url !== "/" + Route.Chapters;
        });
        
        // S'abonne au change
        this.chaptersService.onChangeChapter.subscribe(chapter =>
        {
            if (chapter)
            {
                this.selectedChapter = chapter;
            }
        });

        this.chaptersService.onChangeSubChapter.subscribe(subchapter =>
        {
            if (subchapter)
            {
                this.selectedSubChapter = subchapter;
            }
        });
        
        // Reload le main si la langue change pour update les pipe translate
        this.i18nService.onLanguageChanged.subscribe(() =>
        {
            this.reloading = true;
            this.cd.detectChanges();
            this.reloading = false;
            this.cd.detectChanges();
            this.cd.markForCheck();
        });
    }

    ngOnDestroy(): void
    {
        // Ne pas oublier le désabonnement
        this.routeChangeSubscription?.unsubscribe();
    }

    onMainClick(): void
    {
    }

    onHomeClicked(): void
    {
        this.router.navigate([Route.Chapters]);
    }

    onNotificationClicked(): void
    {
    }

    toggleDebugImg(evt: any)
    {
        this.useDebugImg = evt.currentTarget.checked;
    }
    toggleDebugColumns(evt: any)
    {
        this.useDebugColumns = evt.currentTarget.checked;
    }

    onLetterClicked(): void
    {
        this.modalService.openModalLetter();
    }

    onCalendarClicked(): void
    {
        this.modalService.openModalCalendar();
    }

    onDocumentClicked(): void
    {
        this.modalService.openVariousDocuments();
    }
    onNotesClicked(): void
    {
        this.modalService.openNotes();
    }

    onDisconnectClicked(): void
    {
        this.authService.logout();
        this.router.navigate([Route.Base]);
    }
    
    onChapterClicked(): void
    {
        if (this.selectedChapter)
        {
            this.chaptersService.selectChapter(this.selectedChapter);
            this.router.navigate([Route.Chapter]);
        }
    }
}
