import {NgModule} from '@angular/core';
import {APOLLO_OPTIONS} from 'apollo-angular';
import { ApolloClientOptions, ApolloLink, InMemoryCache } from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';

import { setContext } from '@apollo/client/link/context';
import { DefaultOptions } from '@apollo/client/core';
import { environment } from 'environments/environment';

const uri = environment.graphQLUrl;

export function createApollo(httpLink: HttpLink): ApolloClientOptions<any> 
{
    const basic = setContext(() => ({
        headers: {
            Accept: 'charset=utf-8'
        }
    }));

    const auth = setContext(() =>
    {
        /*const token = localStorage.getItem(Settings.LOCAL_STORAGE_TOKEN);
        if (token === null)
        {
            return {};
        }
        else
        {
            return {
                headers: {
                    Authorization: `Bearer ${token}`
                },
            }
        };*/
    });

    const cache = new InMemoryCache();
    const defaultOptions: DefaultOptions = {
        watchQuery: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'ignore',
        },
        query: {
            fetchPolicy: 'no-cache',
            errorPolicy: 'all',
        },
    }

    const link = ApolloLink.from([basic, auth, httpLink.create({uri})]);
    return { link, cache, defaultOptions };
}

@NgModule({
    providers: [
    {
        provide: APOLLO_OPTIONS,
        useFactory: createApollo,
        deps: [HttpLink],
        },
    ],
})
export class GraphQLModule {}
