
import { CalendarService } from '@/services/calendar.service';
import { GeneralService } from '@/services/general.service';
import { Component, Output, EventEmitter, ElementRef, HostListener, OnDestroy, OnInit, Input } from '@angular/core';
import { environment } from 'environments/environment';
import { Subscription, timer } from 'rxjs';

@Component({
  selector: 'header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit, OnDestroy
{
    private readonly REFRESH_TIME: number = 5000;

    @Input() useHeader: boolean = true;
    @Input() useHome: boolean = false;
    @Input() usePageMode: boolean = false;
    @Input() imgChapter: string = '';
    @Input() titleSubChapter: string = '';

    @Output() onChapterClicked = new EventEmitter();
    @Output() onHomeClicked = new EventEmitter();
    @Output() onNextClicked = new EventEmitter();
    @Output() onPrevClicked = new EventEmitter();
    @Output() onNotificationClicked = new EventEmitter();
    @Output() onNotesClicked = new EventEmitter();

    @Output() onLetterClicked = new EventEmitter();
    @Output() onCalendarClicked = new EventEmitter();
    @Output() onDocumentClicked = new EventEmitter();
    @Output() onDisconnectClicked = new EventEmitter();

    backend: string = environment.backEndUrl;
    
    // Menu
    isMenuOpen: boolean = false;
    isAccountOpen: boolean = false;

    // Notification
    notifSubscription: Subscription | undefined;

    displayedNewLetterDirector: boolean = false;
    displayedNewCalendarEvent: boolean = false;
    displayedNewVariousDoc: boolean = false;
    displayedNotificationNumber: number = 0;

    @HostListener('document:click', ['$event'])
    clickout(event: any)
    {
        // Clique en dehors du composant ferme le composant
        if (!this.eRef.nativeElement.contains(event.target))
        {
            this.isMenuOpen = false;
            this.isAccountOpen = false;
        }
    }
    
    constructor(private eRef: ElementRef, private calenderService: CalendarService, private generalService: GeneralService)
    {
    }

    ngOnInit(): void
    {

        // Appel tout les Xs le service general pour savoir si un élément a été mis à jour
        this.notifSubscription = timer(0, this.REFRESH_TIME).subscribe(general =>
        {
            let yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            Promise.all([this.generalService.getLetterDirector(), this.calenderService.getCalendarEvents(), this.generalService.getVariousDocuments()]).then(data =>
            {
                let checkforNewLetterDirector: boolean = false;
                let checkforNewCalendarEvent: boolean = false;
                let checkforNewVariousDoc: boolean = false;
                let checkforNotificationNumber: number = 0;

                // Letter
                if (data[0])
                {
                    checkforNewLetterDirector = new Date(data[0].updated_at) > yesterday;
                    if (checkforNewLetterDirector) checkforNotificationNumber++;
                }

                // Evenement calendrier                
                data[1].forEach(event =>
                {
                    checkforNewCalendarEvent = new Date(event.updated_at) > yesterday;
                    if (checkforNewCalendarEvent)
                    {
                        // Sort de la boucle si une notification de calendrier est trouvée
                        return;     
                    }
                });
                if (checkforNewCalendarEvent) checkforNotificationNumber++;

                // Documents divers
                checkforNewVariousDoc = new Date(data[2].updated_at) > yesterday && data[2].variousDocuments.length > 0;
                if (checkforNewVariousDoc) checkforNotificationNumber++;
                
                // Update la vue seulement si les données sont différentes pour éviter un flickering dûe au refresh
                if (checkforNotificationNumber != this.displayedNotificationNumber)    this.displayedNotificationNumber = checkforNotificationNumber;
                if (checkforNewLetterDirector != this.displayedNewLetterDirector)      this.displayedNewLetterDirector = checkforNewLetterDirector;
                if (checkforNewCalendarEvent != this.displayedNewCalendarEvent)        this.displayedNewCalendarEvent = checkforNewCalendarEvent;
                if (checkforNewVariousDoc != this.displayedNewVariousDoc)              this.displayedNewVariousDoc = checkforNewVariousDoc;
            });
        });
    }

    ngOnDestroy(): void
    {
        // Important se désabonne aux appels quand le composant est détruit
        this.notifSubscription?.unsubscribe();
    }

    onLetterClick(): void
    {
        this.isMenuOpen = false;
        this.onLetterClicked.emit();
    }

    onCalendarClick(): void
    {
        this.isMenuOpen = false;
        this.onCalendarClicked.emit();
    }

    onDocumentClick(): void
    {
        this.isMenuOpen = false;
        this.onDocumentClicked.emit();
    }

    onBookClick(): void
    {
        this.isMenuOpen = !this.isMenuOpen;
        this.isAccountOpen = false;
    }

    onAccountClick(): void
    {
        this.isAccountOpen = !this.isAccountOpen;
        this.isMenuOpen = false;
    }

    onNotesClick(): void
    {
        this.isMenuOpen = false;
        this.onNotesClicked.emit();
    }
    
    onHomeClick(): void
    {
        this.isMenuOpen = false;
        this.isAccountOpen = false;
        
        this.onHomeClicked.emit();
    }

    onChapterClick(): void
    {
        this.isMenuOpen = false;
        this.isAccountOpen = false;
        
        this.onChapterClicked.emit();
    }

    onNotificationClick(): void
    {
        if (this.displayedNotificationNumber > 0)
        {
            // Simule un clique sur le livre si une notification existe
            this.onBookClick();
        }
    }

    onDisconnectClick(): void
    {
        this.onDisconnectClicked.emit();
    }
}
