import { Background } from '@/models-queries/models';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'template-video',
  templateUrl: './template-video.component.html',
  styleUrls: ['./template-video.component.scss']
})
export class TemplateVideoComponent implements OnInit
{
    @Input() background: Background | undefined;
    
    @Input() title: string = '';
    @Input() description: string = '';
    @Input() videoUrl: string = '';

    backendUrl: string = environment.backEndUrl;
    
    constructor()
    {
    }

    ngOnInit(): void
    {
    }
}
