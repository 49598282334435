
<template-background [background]="background">

    <div class="container">
        <div class="row">
            <div class="offset-1 col-11">

                <div class="d-flex flex-column justify-content-center py-4 py-sm-5">
                    <div class="typo-templatetitle-title">{{ title }}</div>
                    <separator class="mt-4" color="#033878"></separator>
                    <div class="typo-templatetitle-subtitle mt-2">{{ subTitle }}</div>
                </div>
                
            </div>
        </div>
    </div>

</template-background>