
<ng-container *ngIf="chapter">
    
    <div class="hamburger position-fixed top-0 end-0 rounded" (click)="onHamburgerClick()" [ngClass]="isOpen ? 'bg-white' : 'bg-color-1'">
        <img [src]="isOpen ? 'assets/images/HamburgerClose.svg' : 'assets/images/HamburgerOpen.svg'" />
    </div>

    <div class="hamburger-content position-fixed top-0 end-0 flex-column align-items-center bg-white" [ngClass]="{ 'active': isOpen }">

        <div class="hamburger-cell-header text-center w-100 p-4 mb-2" [ngClass]="'bg-id-' + (chapter.chapterNumber - 1)">
            <div class="typo-hamburger-header">{{ 'CHAPTER' | translate }} {{ chapter.chapterNumber }}</div>
        </div>

        <div class="overflow-auto">

            <ng-container *ngFor="let subchapter of chapter.subChapters; let i=index">
                <div class="hamburger-cell d-flex flex-column align-items-center justify-content-center text-center m-3 p-2" (click)="onSubChapterClick(subchapter)" [ngClass]="'bg-id-' + i">
                    <span class="typo-hamburger-title">{{ i + 1 }}</span>
                    <span class="typo-hamburger-subtitle mt-2">{{ subchapter.title }}</span>
                </div>
            </ng-container>
        </div>

    </div>

</ng-container>