
import { CreateNoteResponse, DeleteNoteResponse, General, GeneralResponse, LetterDirector, LetterDirectorResponse, Note, NoteResponse, NotesResponse, UpdateNoteResponse, VariousDocument, VariousDocumentsResponse } from '@/models-queries/models';
import { CREATE_NOTE, DELETE_NOTE, FIND_GENERAL, FIND_LETTER_DIRECTOR, FIND_NOTE, FIND_VARIOUS_DOCUMENT, UPDATE_NOTE } from '@/models-queries/queries';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { AuthenticationService } from './authentication.service';
import { I18nService } from './i18n.service';

@Injectable({ providedIn: 'root' })
export class GeneralService
{
    constructor(private apollo: Apollo, private i18nService: I18nService, private authService: AuthenticationService)
    {
    }

    public async getGeneral(): Promise<General>
    {
        const response = await this.apollo.query<GeneralResponse>(
        { 
            query: FIND_GENERAL, 
            variables: {
                locale: this.i18nService.SuffixLocale
            }
        }).toPromise();
        return response.data.general;
    }

    public async getLetterDirector(): Promise<LetterDirector>
    {
        const response = await this.apollo.query<LetterDirectorResponse>(
        {
            query: FIND_LETTER_DIRECTOR,
            variables: {
                locale: this.i18nService.SuffixLocale
            }
        }).toPromise();
        return response.data.letterDirector;
    }

    public async getVariousDocuments(): Promise<VariousDocument>
    {
        const response = await this.apollo.query<VariousDocumentsResponse>(
        { 
            query: FIND_VARIOUS_DOCUMENT,
            variables: {
                locale: this.i18nService.SuffixLocale
            }
        }).toPromise();
        return response.data.variousDocument;
    }

    public async getNotes(): Promise<Note[]>
    {
        if (!this.authService.CurrentUserValue) return [];

        const response = await this.apollo.query<NotesResponse>({ query: FIND_NOTE, variables:
        {
            uid: this.authService.CurrentUserValue.id
        } }).toPromise();
        return response.data.notes;
    }

    public async createNote(note: Note): Promise<Note | null>
    {
        if (!this.authService.CurrentUserValue) return null;

        const response = await this.apollo.mutate<CreateNoteResponse>({ mutation: CREATE_NOTE, variables: 
        {
            uid: this.authService.CurrentUserValue.id,
            description: note.description
        }}).toPromise();

        if (response.data)
        {
            return response.data.createNote.note;
        }
        else
        {
            return null;
        }
    }

    public async updateNote(note: Note): Promise<Note | null>
    {
        const response = await this.apollo.mutate<UpdateNoteResponse>({ mutation: UPDATE_NOTE, variables: 
        {
            noteId: note.id,
            description: note.description
        }}).toPromise();

        if (response.data)
        {
            return response.data.updateNote.note;
        }
        else
        {
            return null;
        }
    }

    public async deleteNote(note: Note): Promise<Note | null>
    {
        const response = await this.apollo.mutate<DeleteNoteResponse>({ mutation: DELETE_NOTE, variables:
        {
            noteId: note.id
        }}).toPromise();
        
        if (response.data)
        {
            return response.data.deleteNote.note;
        }
        else
        {
            return null;
        }
    }
}