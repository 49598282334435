
<ng-container *ngIf="!reloading">

    <!--
    <div class="position-absolute top-0 start-0 w-100" style="pointer-events: none; z-index: 10000;">
        <div class="container">
            <div class="row" style="height: 5000vh;">
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
                <div class="col bg-debug-red"><div class="h-100 bg-debug-green"></div></div>
            </div>
        </div>
    </div>
    -->

    <header
        [useHeader]="useHeader"
        [usePageMode]="usePageMode"
        [useHome]="useHome"
        [imgChapter]="selectedChapter ? selectedChapter.icon.url : ''"
        [titleSubChapter]="selectedSubChapter ? selectedSubChapter.title : ''"

        (onLetterClicked)="onLetterClicked()" 
        (onCalendarClicked)="onCalendarClicked()" 
        (onDocumentClicked)="onDocumentClicked()"
        (onHomeClicked)="onHomeClicked()"
        (onNotesClicked)="onNotesClicked()"
        (onChapterClicked)="onChapterClicked()"
        (onNotificationClicked)="onNotificationClicked()"
        (onDisconnectClicked)="onDisconnectClicked()">
    </header>
    
    <modal-card></modal-card>
    <modal-calendar></modal-calendar>
    <modal-letter></modal-letter>
    <modal-various-document></modal-various-document>
    <modal-img-zoom></modal-img-zoom>
    <modal-note></modal-note>
    
    <router-outlet></router-outlet>
    
</ng-container>