import { PaletteColor } from '@/models-queries/models';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'calendar-cell',
  templateUrl: './calendar-cell.component.html',
  styleUrls: ['./calendar-cell.component.scss']
})
export class CalendarCellComponent
{
    @Input() color: PaletteColor | undefined;
    @Input() header: string | undefined;
    @Input() eventDate: Date | undefined;
    @Input() content: string | undefined;
    @Input() region: string | undefined;

    isOpen: boolean = false;

    constructor()
    {
    }

    toggleClick(): void
    {
        this.isOpen = !this.isOpen;
    }
}
