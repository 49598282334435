import { Background } from '@/models-queries/models';
import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'template-title',
  templateUrl: './template-title.component.html',
  styleUrls: ['./template-title.component.scss']
})
export class TemplateTitleComponent implements OnInit
{
    @Input() background: Background | undefined;
    
    @Input() title: string = '';
    @Input() subTitle: string = '';

    constructor()
    {
    }

    ngOnInit(): void
    {
    }
}
