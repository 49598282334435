
<div class="container-fluid h-100 bg-color-1 d-flex align-items-center position-relative">
     
    <img class="logo position-absolute top-0 start-50 my-5 logo" src="assets/images/Logo_Servier.svg" />

    <div class="container">
        <div class="row">
            <div class="col-10 offset-1 col-md-4 offset-md-4">

                <div class="d-flex flex-column justify-content-center align-items-center">
                    <div>
                        <span class="typo-auth-title text-color-2"><i>my</i></span><span class="typo-auth-title">Guide</span>
                    </div>
                    
                    <input class="form-control mt-4" type="email" #userName required placeholder="Mail" />
                    <input class="form-control mt-3 mb-4" type="password" #userPassword required placeholder="Password" />

                    <div *ngIf="connexionError" class="color-error text-center mb-2">Username or password incorrect.</div>
                    
                    <button (click)="onAuthenticationSubmit(userName.value, userPassword.value)">Connect</button>
                </div>

            </div>
        </div>
    </div>

</div>
