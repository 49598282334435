
<template-background [background]="background" *ngIf="carouselElements.length > 0">
    
    <div class="container">
        
        <!-- Titre produit -->
        <div class="row">
            <div class="offset-1 col-11">
                <div class="my-4 my-sm-5 typo-template-title">{{ carouselTitle }}</div>
            </div>
        </div>

        <!-- Carousel -->
        <div class="row">
            <div class="col-1 offset-lg-2 g-0 d-flex justify-content-end">
                <img *ngIf="currentElementId > 0" src="assets/pictos/ArrowLeftBlue.svg" (click)="onPrevClick()" class="arrow" />
            </div>
    
            <div class="col-10 col-lg-6">
    
                <div class="mb-4 mb-sm-5 carousel-element flex-column align-items-center" *ngFor="let elem of carouselElements; let i=index" (click)="onElementClick(elem)" [ngClass]="{ 'active': i === currentElementId }">
                    <img class="carousel-img imgfitparent" [src]="backendUrl + elem.image.url" />
                </div>
                
            </div>
    
            <div class="col-1 g-0 d-flex justify-content-start">
                <img *ngIf="currentElementId < carouselElements.length - 1" src="assets/pictos/ArrowRightBlue.svg" (click)="onNextClick()" class="arrow" />
            </div>

        </div>
    </div>

</template-background>