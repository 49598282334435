import { HttpClientModule } from '@angular/common/http';
import { APP_INITIALIZER, LOCALE_ID, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { BrowserModule } from '@angular/platform-browser';
import { registerLocaleData } from '@angular/common';

import { AppRoutingModule } from './app-routing.module';
import { GraphQLModule } from './graphql.module';
import { MainComponent } from './pages/main/main.component';
import { HeaderComponent } from './components/header/header.component';
import { ChaptersComponent } from './pages/chapters/chapters.component';
import { ChapterComponent } from './pages/chapter/chapter.component';

import { MatSnackBarModule } from '@angular/material/snack-bar';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { PageComponent } from './pages/page/page.component';
import { LeftMenuComponent } from './components/left-menu/left-menu.component';
import { HamburgerComponent } from './components/hamburger/hamburger.component';
import { TemplateCardComponent } from './components/template-card/template-card.component';
import { TemplateCarouselComponent } from './components/template-carousel/template-carousel.component';
import { TemplateGridComponent } from './components/template-grid/template-grid.component';
import { TemplateTitleComponent } from './components/template-title/template-title.component';
import { TemplateVideoComponent } from './components/template-video/template-video.component';
import { BtnResourceComponent } from './components/btn-resource/btn-resource.component';
import { ModalCardComponent } from './components/modal-card/modal-card.component';
import { ModalCalendarComponent } from './components/modal-calendar/modal-calendar.component';
import { TemplateBackgroundComponent } from './components/template-background/template-background.component';
import { SeparatorComponent } from './components/separator/separator.component';
import { CalendarCellComponent } from './components/calendar-cell/calendar-cell.component';
import { I18nService } from './services/i18n.service';
import { TranslatePipe } from './pipe/translate.pipe';
import { SafePipe } from './pipe/safepipe';
import { ModalLetterComponent } from './components/modal-letter/modal-letter.component';
import { ModalVariousDocumentComponent } from './components/modal-various-document/modal-various-document.component';
import { NotificationComponent } from './components/notification/notification.component';
import { ModalImgZoomComponent } from './components/modal-img-zoom/modal-img-zoom.component';
import { AuthenticationComponent } from './pages/authentication/authentication.component';
import { ModalNoteComponent } from './components/modal-note/modal-note.component';

import localeFr from '@angular/common/locales/fr';
registerLocaleData(localeFr);

@NgModule({
    declarations: [
        MainComponent,
        HeaderComponent,

        AuthenticationComponent,
        ChaptersComponent,
        ChapterComponent,
        PageComponent,
        LeftMenuComponent,
        HamburgerComponent,
        TemplateCardComponent,
        TemplateCarouselComponent,
        TemplateGridComponent,
        TemplateTitleComponent,
        TemplateVideoComponent,
        BtnResourceComponent,
        TemplateBackgroundComponent,
        SeparatorComponent,
        ModalCardComponent,
        ModalCalendarComponent,
        ModalLetterComponent,
        ModalVariousDocumentComponent,
        CalendarCellComponent,
        
        TranslatePipe,
        SafePipe,
        NotificationComponent,
        ModalImgZoomComponent,
        ModalNoteComponent,
    ],
    imports: [
        BrowserModule,
        AppRoutingModule,
        GraphQLModule,
        HttpClientModule,
        ReactiveFormsModule,
        FormsModule,

        MatSnackBarModule,
        BrowserAnimationsModule,
    ],
    providers: [
        // S'assure que le i18nService a téléchargé les textes avant que l'app ne se lance
        { provide: APP_INITIALIZER, useFactory: (i18nService: I18nService) => () => i18nService.loadTexts(), deps: [I18nService], multi: true },
        { provide: LOCALE_ID, useValue: "fr-FR" }
    ],
    bootstrap: [MainComponent]
})
export class AppModule { }
