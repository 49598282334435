
<div *ngIf="imageZoomModal" class="modal position-fixed align-items-center justify-content-center" [ngClass]="{ 'active': imageZoomModal.isModalOpen }">
    <div class="mod-dialog">

        <!-- Header-->
        <div class="mod-header d-flex flex-row align-items-center justify-content-between typo-title">
            <div class="px-2"></div>
            <div class="typo-modal-header">{{ imageZoomModal.header }}</div>
            <div>
                <img class="mod-close mx-3" src="assets/pictos/NotesBlue.svg" (click)="onNoteClick()"/>
                <img class="mod-close mx-3" src="assets/pictos/CloseBlue.svg" (click)="onCloseClick()" />
            </div>
        </div>
        
        <!-- Body -->
        <div class="mod-body d-flex justify-content-center px-2 pb-2 px-lg-3 pb-lg-3">

            <img class="imgfitparent" [src]="backendUrl + imageZoomModal.imageUrl"  />

        </div>

    </div>
</div>