
<div *ngIf="variousDocModal" class="modal position-fixed align-items-center justify-content-center" [ngClass]="{ 'active': variousDocModal.isModalOpen }">

    <div class="mod-dialog position-relative">

        <!-- Entête-->
        <div class="mod-header bg-color-4 d-flex flex-row align-items-center justify-content-between typo-title">
            <div class="px-2"></div>
            <div class="d-flex align-items-center">
                <img class="icon-medium" src="assets/pictos/Documents.svg" />
                <span class="ms-3 typo-modal-header">{{ variousDocModal.header }}</span>
            </div>
            <img class="mod-close mx-3" src="assets/pictos/CloseBlue.svg" (click)="onCloseClick()"/>
        </div>
        
        <!-- Corps -->
        <div class="mod-body position-relative d-flex flex-column">
            <div class="overflow-auto">

                <div class="d-flex flex-row align-items-center justify-content-between py-2 px-4" *ngFor="let doc of variousDocs; let i=index" 
                    [ngStyle]="{ 'background-color': i % 2 ? '#EAF6FB' : 'white' }">
                    <div class="typo-document">{{ doc.name }}</div>
                    <div *ngIf="doc.resource" class="py-2">
                        <btn-resource name="{{ 'DOWNLOAD' | translate }}" [useDownload]="true" [resourceUrl]="doc.resource.url"></btn-resource>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>