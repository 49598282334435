import { Background } from '@/models-queries/models';
import { Component, Input, OnInit } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'template-background',
  templateUrl: './template-background.component.html',
  styleUrls: ['./template-background.component.scss']
})
export class TemplateBackgroundComponent implements OnInit
{    
    @Input() background: Background | undefined;
    
    backendUrl: string = environment.backEndUrl;
  
    constructor()
    {
    }

    ngOnInit(): void
    {
    }
}
