
<template-background [background]="background">
    
    <div class="container">
        <div class="row g-0">

            <div class="offset-1 col-10">

                <div class="container my-4 my-sm-5 overflow-hidden">
                    <div class="row video-element g-0">

                        <div class="col-12 col-lg-6 d-flex align-items-center">
                            <div class="position-relative ratio ratio-16x9 mb-2 mb-lg-0">
                                <iframe class="position-absolute top-0 start-0 w-100 h-100" [src]="videoUrl | safe"></iframe>
                            </div>
                        </div>

                        <div class="col-12 col-lg-6 d-flex align-items-center">
                            <div class="mx-4">
                                <div class="typo-title text-color-1 mb-4">{{ title }}</div>
                                <div class="mb-2 mb-lg-auto typo-description text-color-1  pre-line">{{ description }}</div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>

</template-background>


<!--
<template-background [background]="background">
    
    <div class="container">
        <div class="row align-items-center g-0">

            <div class="offset-1 col-10 d-flex my-4 my-sm-5 p-2 video-element">
                <div class="position-relative ratio ratio-16x9">
                    <iframe class="position-absolute top-0 start-0 w-100 h-100" [src]="videoUrl | safe"></iframe>
                </div>

                <div class="ps-4 d-flex flex-column justify-content-center align-items-center">
                    <div class="typo-title text-color-1 mb-4">{{ title }}</div>
                    <div class="mb-2 mb-lg-auto typo-description text-color-1  pre-line">{{ description }}</div>
                </div>
            </div>

        </div>
    </div>

</template-background>
-->