import { Document, Modal } from '@/models-queries/models';
import { GeneralService } from '@/services/general.service';
import { ModalService } from '@/services/modal.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'modal-various-document',
  templateUrl: './modal-various-document.component.html',
  styleUrls: ['./modal-various-document.component.scss']
})
export class ModalVariousDocumentComponent implements OnInit
{
    variousDocs: Document[] = [];

    variousDocModal: Modal | undefined;

    private modalSubscription: Subscription | undefined;
    
    constructor(private generalService: GeneralService, private modalService: ModalService)
    {
    }

    ngOnInit(): void
    {
        this.modalSubscription = this.modalService.onVariousDocModalChange().subscribe(modalData =>
        {
            this.variousDocModal = modalData;
            this.refreshModal();
        });
        console.log("refreshModal variousdoc");
    }
    
    ngOnDestroy(): void
    {
        if (this.modalSubscription) this.modalSubscription.unsubscribe();
    }

    refreshModal()
    {
        this.generalService.getVariousDocuments().then(docs => this.variousDocs = docs.variousDocuments);
    }
    
    onCloseClick(): void
    {
        this.modalService.closeVariousDocuments();
    }
}
