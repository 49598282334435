
import { Component, OnInit, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'left-menu',
  templateUrl: './left-menu.component.html',
  styleUrls: ['./left-menu.component.scss']
})
export class LeftMenuComponent implements OnInit
{
    @Output() onHomeClicked = new EventEmitter();
    @Output() onNotificationClicked = new EventEmitter();

    constructor()
    {
    }

    ngOnInit(): void
    {
    }

    onHomeClick(): void
    {
        this.onHomeClicked.emit();
    }

    onNotificationClick(): void
    {
        this.onNotificationClicked.emit();
    }
}
