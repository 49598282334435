import { ImageZoomModal } from '@/models-queries/models';
import { ModalService } from '@/services/modal.service';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'modal-img-zoom',
  templateUrl: './modal-img-zoom.component.html',
  styleUrls: ['./modal-img-zoom.component.scss']
})
export class ModalImgZoomComponent implements OnInit, OnDestroy
{
    backendUrl: string = environment.backEndUrl;
    
    imageZoomModal: ImageZoomModal | undefined;
    
    private modalSubscription: Subscription | undefined;
    
    constructor(private modalService: ModalService, @Inject(DOCUMENT) private document: Document)
    {
    }

    ngOnInit(): void
    {
        this.modalSubscription = this.modalService.onImageZoomModalChange().subscribe(modalData =>
        {
            // Evite le scroll du body en mode modal
            if (modalData.isModalOpen)  this.document.body.classList.add("modal-open");
            else                        this.document.body.classList.remove("modal-open");
            
            this.imageZoomModal = modalData
        });
    }

    ngOnDestroy(): void
    {
        if (this.modalSubscription) this.modalSubscription.unsubscribe();
    }

    onNoteClick(): void
    {
        this.modalService.openNotes();
    }
    
    onCloseClick(): void
    {
        this.modalService.closeModalImageZoom();
    }
}
