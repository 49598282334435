
<div *ngIf="cardModal" class="modal position-fixed align-items-center justify-content-center" [ngClass]="{ 'active': cardModal.isModalOpen }">
    <div class="mod-dialog position-relative"> 
        
        <!-- Entête-->
        <div class="mod-header bg-color-5 d-flex flex-row align-items-center justify-content-between typo-title">
            <div class="px-2"></div>
            <div class="d-flex align-items-center">
            </div>
            <div class="d-flex align-items-center">
                <btn-resource class="me-3" *ngIf="cardModal.resource" [useDownload]="true" [resourceUrl]="cardModal.resource.url"></btn-resource>
                <img class="mod-close mx-3" src="assets/pictos/NotesBlue.svg" (click)="onNoteClick()"/>
                <img class="mod-close mx-3" src="assets/pictos/CloseBlue.svg" (click)="onCloseClick()"/>
            </div>
        </div>

        <!-- Body -->
        <div class="mod-body overflow-y">
            
            <div class="container">
                <div class="row">

                    <div *ngIf="cardModal.image" class="col-12 col-lg-4 d-flex align-items-center justify-content-center">
                        <img class="mb-2 mb-lg-0 imgfitparent" [src]="backendUrl + cardModal.image.url" />
                    </div>
                    
                    <div class="col-12 col-lg-8 g-0 p-3">
                        <div class="d-flex flex-column h-100">
                            <div class="mt-auto"></div>
                            <div class="typo-title text-color-1 mb-4">{{ cardModal.title }}</div>
                            <div class="mb-2 mb-lg-auto typo-description text-color-19 pre-line">{{ cardModal.description }}</div>
                        </div>
                    </div>

                </div>
            </div>

        </div>

    </div>
</div>