
import { Injectable } from '@angular/core';

declare var require: any
const FileSaver = require('file-saver');

@Injectable({ providedIn: 'root' })
export class DownloaderService
{
    constructor()
    {

    }

    public download(fullPathUrl: string): void
    {
        window.open(fullPathUrl, "_blank");
        //var filename = fullPathUrl.substring(fullPathUrl.lastIndexOf('/') + 1);
        //FileSaver.saveAs(fullPathUrl, filename);
    }
}