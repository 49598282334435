import { Background, GridElement, GridTag } from '@/models-queries/models';
import { ModalService } from '@/services/modal.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'template-grid',
  templateUrl: './template-grid.component.html',
  styleUrls: ['./template-grid.component.scss']
})
export class TemplateGridComponent implements OnInit
{
    @Input() background: Background | undefined;
    @Input() gridElements: GridElement[] = [];

    @Output() onElementClicked: EventEmitter<GridElement> = new EventEmitter<GridElement>();

    backendUrl: string = environment.backEndUrl;

    gridElementsGroupByTag: Map<string, GridElement[]> = new Map<string, GridElement[]>();
    
    constructor(private modalService: ModalService)
    {
    }

    ngOnInit(): void 
    {
        this.gridElementsGroupByTag = this.groupByGridTag(this.gridElements);
    }

    onGridElementClick(elemGrid: GridElement): void
    {
        this.modalService.openModalCard(elemGrid.title, elemGrid.description, elemGrid.image, elemGrid.resource);
    }

    groupByGridTag(gridElements: GridElement[]): Map<string, GridElement[]>
    {
        // Groupe par tag
        let groupByTag: Map<string, GridElement[]> = new Map<string, GridElement[]>();
        gridElements.forEach(elem =>
        {
            if (elem.grid_tag)
            {
                if (!groupByTag.has(elem.grid_tag.title))
                {
                    groupByTag.set(elem.grid_tag.title, [elem]);
                }
                else
                {
                    groupByTag.get(elem.grid_tag.title)?.push(elem);
                }
            }
        });

        return groupByTag;
    }
}
