import { DownloaderService } from '@/services/downloader.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'btn-resource',
  templateUrl: './btn-resource.component.html',
  styleUrls: ['./btn-resource.component.scss']
})
export class BtnResourceComponent implements OnInit
{
    @Input() name: string = '';
    @Input() useDownload: boolean = false;
    @Input() resourceUrl: string | undefined;      
    @Output() click = new EventEmitter();
    
    backendUrl: string = environment.backEndUrl;

    constructor(private downloaderService: DownloaderService)
    {
    }

    ngOnInit(): void 
    {
    }

    onClick()
    {
        if (this.useDownload && this.resourceUrl)
        {
            this.downloaderService.download(this.backendUrl + this.resourceUrl);
        }
        this.click.emit();
    }
}
