
import { Page, PageResponse } from '@/models-queries/models';
import { FIND_PAGE } from '@/models-queries/queries';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';

@Injectable({ providedIn: 'root' })
export class PagesService
{
    constructor(private apollo: Apollo)
    {
    }
    
    public async getPage(pageId: number): Promise<Page>
    {
        const response = await this.apollo.query<PageResponse>({ query: FIND_PAGE, 
            variables: { 
                pageId: pageId 
            } 
        }).toPromise();

        return response.data.page;
    }
}