
<div *ngIf="notesModal" class="modal position-fixed align-items-center justify-content-center" [ngClass]="{ 'active': notesModal.isModalOpen }">

    <div class="mod-dialog position-relative">

        <!-- Entête-->
        <div class="mod-header bg-color-4 d-flex flex-row align-items-center justify-content-between typo-title">
            <div class="px-2">
                <img *ngIf="!isAddingNote" class="mod-add mx-3" src="assets/pictos/AddNote.svg" (click)="onAddClick()"/>
            </div>
            <div class="d-flex align-items-center">
                <img class="icon-medium" src="assets/pictos/NotesBlue.svg" />
                <span class="ms-3 typo-modal-header">{{ notesModal.header }}</span>
            </div>
            <img class="mod-close mx-3" src="assets/pictos/CloseBlue.svg" (click)="onCloseClick()"/>
        </div>
        
        <!-- Corps -->
        <div class="mod-body position-relative d-flex flex-column justify-content-between p-4">
            
            <!-- Ajout d'une note -->
            <div *ngIf="isAddingNote" class="note d-flex flex-column bg-color-4 mb-2 p-2">
                <div class="typo-document">
                    <textarea class="w-100 mb-2" rows="6" [(ngModel)]="newNote.description" placeholder="{{ 'ADD_NOTE_HERE' | translate }}"></textarea>
                </div>
                <div class="d-flex justify-content-end">
                    <img class="icon-medium mx-2" src="assets/pictos/NoteSave.svg" (click)="onSaveNewNoteClick()"/>
                    <img class="icon-medium mx-2" src="assets/pictos/NoteCancel.svg" (click)="onCancelNewNoteClick()"/>
                </div>
            </div>

            <!-- Liste des notes-->
            <div *ngIf="!isAddingNote" class="overflow-auto">

                <div class="note d-flex flex-column bg-color-4 mb-2 p-2" *ngFor="let note of notes; last as isLast" [ngClass]="{ 'mb-2' : !isLast }">
                    <div class="typo-document pre-line">
                        <div class="typo-date mb-2">{{ note.created_at | date : 'dd/MM/yyyy' }}</div>
                        <textarea *ngIf="note.isEditing" class="w-100 mb-2 typo-note" rows="6" [(ngModel)]='note.description'></textarea>
                        <div *ngIf="!note.isEditing" class="typo-note">{{ note.description }}</div>
                    </div>
                    <div class="d-flex justify-content-end mt-2">
                        <img *ngIf="!note.isDeleting && !note.isEditing" class="icon-medium mx-2" src="assets/pictos/NoteEdit.svg" (click)="onEditClick(note)"/>
                        <img *ngIf="!note.isDeleting && note.isEditing" class="icon-medium mx-2" src="assets/pictos/NoteSave.svg" (click)="onSaveClick(note)"/>
                        <img *ngIf="!note.isDeleting && !note.isEditing" class="icon-medium mx-2" src="assets/pictos/NoteDelete.svg" (click)="onDeleteClick(note)"/>
                        <div *ngIf="note.isDeleting">
                            {{ 'CONFIRM_DELETE' | translate }}
                            <img class="icon-medium mx-3" src="assets/pictos/NoteConfirm.svg" (click)="onConfirmDeleteClick(note)"/>
                            <img class="icon-medium mx-3" src="assets/pictos/CloseBlue.svg" (click)="onCancelDeleteClick(note)"/>
                        </div>
                    </div>
                </div>

            </div>
        </div>

    </div>
</div>