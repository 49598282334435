
<div *ngIf="useHeader" class="container-fluid bg-color-80-1">

    <div class="row">
        <div class="col">

            <div class="status-bar-mobile d-block d-xl-none"></div>

        </div>
    </div>

    <div class="row align-items-center">

        <!-- Partie gauche -->
        <div class="col-3 col-md-3 ps-3 py-3">
            <img *ngIf="!usePageMode" class="logo" src="assets/images/Logo_Servier.svg" />
            <img *ngIf="usePageMode" class="icon-standard" [src]="backend + imgChapter" (click)="onChapterClick()" />
        </div>

        <!-- Partie centrale -->
        <div class="col-6 col-md-6 d-flex justify-content-center align-items-center text-center">
            <div *ngIf="!usePageMode" class="d-none d-sm-block">
                <span class="typo-header text-color-2"><i>my</i></span><span class="typo-header">Guide</span>
            </div>
            <div *ngIf="usePageMode">
                <span class="typo-header">{{ titleSubChapter }}</span>
            </div>
        </div>

        <!-- Partie droite -->
        <div class="col-3 col-md-3 pe-3 py-3">

            <div class="d-flex flex-row justify-content-end">

                <!-- Votre compte -->
                <img *ngIf="!usePageMode" class="icon-standard" src="assets/pictos/Account.svg" (click)="onAccountClick()" />
                <div *ngIf="!usePageMode" class="position-relative">
    
                    <div [ngClass]="{ 'active': isAccountOpen }" class="menu position-absolute end-0 top-0 flex-column bg-white">
                        <div class="d-flex justify-content-between align-items-center p-3" (click)="onDisconnectClick()">
                            <img class="icon-medium" src="assets/pictos/SignOut.svg" />
                            <div class="text-nowrap typo-menu text-start w-100 mx-5">{{ 'DISCONNECT' | translate }}</div>
                        </div>
                    </div>
                </div>

                <!-- Notifications -->
                <div *ngIf="!usePageMode" class="position-relative">
                    <div *ngIf="displayedNotificationNumber > 0" class="chip typo-chip position-absolute top-0 end-0 d-flex align-items-center justify-content-center text-center">{{ displayedNotificationNumber }}</div>
                    <img class="icon-standard ms-3 ms-md-4" src="assets/pictos/Notification.svg" (click)="onNotificationClick()" />
                </div>
    
                <!-- Menu livre -->
                <img *ngIf="!usePageMode" class="icon-standard ms-3 ms-md-4" [src]="isMenuOpen ? 'assets/pictos/BookOpen.svg' : 'assets/pictos/BookClose.svg'" (click)="onBookClick()" />
                <div *ngIf="!usePageMode" class="position-relative">
    
                    <div [ngClass]="{ 'active': isMenuOpen }" class="menu position-absolute end-0 top-0 flex-column bg-white">
                        <div class="d-flex justify-content-between align-items-center p-3" (click)="onLetterClick()">
                            <img class="icon-medium" src="assets/pictos/Letter.svg" />
                            <div class="text-nowrap typo-menu text-start w-100 mx-5">{{ 'DIRECTOR_LETTER' | translate }}</div>
                            <img *ngIf="displayedNewLetterDirector" class="icon-small" src="assets/images/New.svg" />
                        </div>
                        <div class="d-flex justify-content-between align-items-center p-3 bg-color-20" (click)="onCalendarClick()">
                            <img class="icon-medium" src="assets/pictos/Calendar.svg" />
                            <div class="text-nowrap typo-menu text-start w-100 mx-5">{{ 'CALENDAR' | translate }}</div>
                            <img *ngIf="displayedNewCalendarEvent" class="icon-small" src="assets/images/New.svg" />
                        </div>
                        <div class="d-flex justify-content-between align-items-center p-3" (click)="onDocumentClick()">
                            <img class="icon-medium" src="assets/pictos/Documents.svg" />
                            <div class="text-nowrap typo-menu text-start w-100 mx-5">{{ 'VARIOUS_DOCUMENTS' | translate }}</div>
                            <img *ngIf="displayedNewVariousDoc" class="icon-small" src="assets/images/New.svg" />
                        </div>
                        <div class="d-flex justify-content-between align-items-center p-3 bg-color-20" (click)="onNotesClick()">
                            <img class="icon-medium" src="assets/pictos/NotesBlue.svg" />
                            <div class="text-nowrap typo-menu text-start w-100 mx-5">{{ 'NOTES' | translate }}</div>
                        </div>
                    </div>
                </div>
                
                <!-- Notes -->
                <img *ngIf="usePageMode" class="icon-standard" src="assets/pictos/NotesWhite.svg" (click)="onNotesClick()" />

                <!-- Accueil / Retour chapitres -->
                <img *ngIf="useHome" class="icon-standard ms-3 ms-md-4" src="assets/pictos/Home.svg" (click)="onHomeClick()" />
            </div>

        </div>
    </div>

</div>