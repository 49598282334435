
<template-background [background]="background" *ngIf="gridElements.length > 0">

    <div class="container">
        
        <ng-container *ngFor="let elemGroup of gridElementsGroupByTag | keyvalue; let i=index">

            <!-- Titre par grid-->
            <div class="row">
                <div class="offset-1 col-11">
                    <div class="my-4 my-sm-5 typo-template-title">{{ elemGroup.key }}</div>
                </div>
            </div>
            
            <!-- Elements par group -->
            <div class="row">
                <div class="offset-1 col-10 d-flex flex-wrap">
    
                            
                    <div class="col-12 col-md-6 col-lg-4 px-2 d-flex flex-column justify-content-center align-items-center text-center mb-5" *ngFor="let elemGrid of elemGroup.value" (click)="onGridElementClick(elemGrid)">
                        <div class="typo-template-subtitle h-title-grid d-flex align-items-center mb-2">{{ elemGrid.title }}</div>
                        <div class="ratio ratio-1x1">
                            <img class="grid-img imgfitparent" [src]="backendUrl + elemGrid.image.url" />
                        </div>
                    </div>


                </div>
            </div>

        </ng-container>

    </div>

</template-background>