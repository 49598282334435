import { Chapter, SubChapter } from '@/models-queries/models';
import { Route } from '@/models-queries/route';
import { ChaptersService } from '@/services/chapters.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-chapter',
  templateUrl: './chapter.component.html',
  styleUrls: ['./chapter.component.scss']
})
export class ChapterComponent implements OnInit
{
    backend: string = environment.backEndUrl;
    
    chapter: Chapter | undefined;

    constructor(private router: Router, private chaptersService: ChaptersService)
    {
    }

    ngOnInit(): void
    {
        this.chapter = this.chaptersService.currentChapter;
    }

    onSubChapterClick(subChapter: SubChapter): void
    {
        if (subChapter.page)
        {
            this.chaptersService.selectSubChapter(subChapter);
            this.router.navigate([Route.Page, subChapter.page.id]);
        }
    }

    getChapterColor(chapterId: number)
    {
        if (this.chapter)
        {
            return this.lightenDarkenColor(this.chapter.palette_color.codeHexadecimal, chapterId * 10);
        }
        return "white";
    }

    lightenDarkenColor(color: string, amt: number)
    {
        let num: number = parseInt(color, 16);
    
        var r = (num >> 16) + amt;
    
        if ( r > 255 ) r = 255;
        else if  (r < 0) r = 0;
    
        var b = ((num >> 8) & 0x00FF) + amt;
    
        if ( b > 255 ) b = 255;
        else if  (b < 0) b = 0;
    
        var g = (num & 0x0000FF) + amt;
    
        if ( g > 255 ) g = 255;
        else if  ( g < 0 ) g = 0;
    
        return (g | (b << 8) | (r << 16)).toString(16);
    }
}
