
<div *ngIf="letterModal" class="modal position-fixed align-items-center justify-content-center" [ngClass]="{ 'active': letterModal.isModalOpen }">

    <div class="mod-dialog position-relative">

        <!-- Entête-->
        <div class="mod-header bg-color-4 d-flex flex-row align-items-center justify-content-between typo-title">
            <div class="px-2"></div>
            <div class="d-flex align-items-center">
                <img class="icon-medium" src="assets/pictos/Letter.svg" />
                <span class="ms-3 typo-modal-header">{{ letterModal.header }}</span>
            </div>
            <div class="d-flex align-items-center">
                <div *ngIf="directorLetterMedia" class="d-flex justify-content-center">
                    <btn-resource name="{{ 'DOWNLOAD' | translate }}" [useDownload]="true" [resourceUrl]="directorLetterMedia.url"></btn-resource>
                </div>
                <div>
                    <img class="mod-close mx-3" src="assets/pictos/CloseBlue.svg" (click)="onCloseClick()" />
                </div>
            </div>
        </div>
        
        <!-- Corps -->
        <div class="mod-body d-flex flex-column justify-content-between p-4">

            <div *ngIf="directorLetter" class="typo-letter pre-line overflow-auto mb-4">
                {{ directorLetter }}
            </div>

        </div>

    </div>
</div>