
import { Media, CardModal, Modal, ImageZoomModal } from '@/models-queries/models';
import { AfterViewInit, ElementRef, Injectable, ViewChild } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { I18nService } from './i18n.service';

@Injectable({ providedIn: 'root' })
export class ModalService
{
    // Evenement à s'abonner pour être informé de changement dans la fenêtre carte modal
    public onCardModalChange(): BehaviorSubject<CardModal>
    {
        return this.modalCardSubject;
    }
    // Evenement à s'abonner pour être informé de changement dans le calendrier
    public onCalendarModalChange(): BehaviorSubject<Modal>
    {
        return this.modalCalendarSubject;
    }
    // Evenement à s'abonner pour être informé de changement dans la lettre au directeur
    public onLetterModalChange(): BehaviorSubject<Modal>
    {
        return this.modalLetterSubject;
    }
    // Evenement à s'abonner pour être informé de changement dans les documents divers
    public onVariousDocModalChange(): BehaviorSubject<Modal>
    {
        return this.modalVariousDocSubject;
    }
    // Evenement à s'abonner pour être informé de changement dans l'image zoom
    public onImageZoomModalChange(): BehaviorSubject<ImageZoomModal>
    {
        return this.modalImageZoomSubject;
    }
    // Evenement à s'abonner pour être informé de changement dans les notes
    public onNotesModalChange(): BehaviorSubject<Modal>
    {
        return this.modalNotesSubject;
    }

    private modalCardSubject: BehaviorSubject<CardModal>;
    private modalCalendarSubject: BehaviorSubject<Modal>;
    private modalLetterSubject: BehaviorSubject<Modal>;
    private modalVariousDocSubject: BehaviorSubject<Modal>;
    private modalImageZoomSubject: BehaviorSubject<ImageZoomModal>;
    private modalNotesSubject: BehaviorSubject<Modal>;

    private emptyCardModal: CardModal = new CardModal(false, '', '', '', null, null);
    private emptyZoomModal: ImageZoomModal = new ImageZoomModal(false, '', '');
    private emptyModal: Modal = new Modal(false, '');

    constructor(private i18nService: I18nService)
    {
        this.modalCardSubject = new BehaviorSubject<CardModal>(this.emptyCardModal);
        this.modalCalendarSubject = new BehaviorSubject<Modal>(this.emptyModal);
        this.modalLetterSubject = new BehaviorSubject<Modal>(this.emptyModal);
        this.modalVariousDocSubject = new BehaviorSubject<Modal>(this.emptyModal);
        this.modalImageZoomSubject = new BehaviorSubject<ImageZoomModal>(this.emptyZoomModal);
        this.modalNotesSubject = new BehaviorSubject<Modal>(this.emptyModal);
    }

    public openModalCard(title: string, description: string, image: Media, resource: Media): void
    {
        this.modalCardSubject.next(new CardModal(true, "", title, description, image, resource));
    }

    public closeModalCard(): void
    {
        this.modalCardSubject.next(this.emptyCardModal);
    }

    public openModalCalendar(): void
    {
        const headerName = this.i18nService.getText("CALENDAR");
        this.modalCalendarSubject.next(new Modal(true, headerName));
    }

    public closeModalCalendar(): void
    {
        this.modalCalendarSubject.next(this.emptyModal);
    }

    public openModalLetter(): void
    {
        const headerName = this.i18nService.getText("DIRECTOR_LETTER");
        this.modalLetterSubject.next(new Modal(true, headerName));
    }

    public closeModalLetter(): void
    {
        this.modalLetterSubject.next(this.emptyModal);
    }

    public openVariousDocuments(): void
    {
        const headerName = this.i18nService.getText("VARIOUS_DOCUMENTS");
        this.modalVariousDocSubject.next(new Modal(true, headerName));
    }

    public closeVariousDocuments(): void
    {
        this.modalVariousDocSubject.next(this.emptyModal);
    }

    public openImageZoom(imageUrl: string): void
    {
        this.modalImageZoomSubject.next(new ImageZoomModal(true, '', imageUrl));
    }

    public closeModalImageZoom(): void
    {
        this.modalImageZoomSubject.next(this.emptyZoomModal);
    }

    public openNotes(): void
    {
        const headerName = this.i18nService.getText("NOTES");
        this.modalNotesSubject.next(new Modal(true, headerName));
    }
    
    public closeModalNotes(): void
    {
        this.modalNotesSubject.next(this.emptyModal);
    }
}