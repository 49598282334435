
<div class="calendar-cell d-flex flex-column align-items-center mb-2" [ngStyle]="{ 'border-color': color ? '#' + color.codeHexadecimal : 'white' }" (click)="toggleClick()">
    <div class="typo-cell-title p-2 w-100 text-center" [ngStyle]="{ 'background-color': color ? '#' + color.codeHexadecimal : 'white' }">
        <div>{{ header }}</div>
        <div class="d-flex justify-content-center align-items-center py-2"><separator></separator></div>
        <div>{{ region }}</div>
    </div>
    <div class="typo-cell-content p-2 text-center">{{ eventDate | date : 'dd.MM.yyyy' }}</div>
    <div class="content typo-cell-content text-center" [ngClass]="{ 'active': isOpen }">
        <div class="mt-3 mb-2">{{ content }}</div>
        <svg class="icon-medium" viewBox="0 0 800 600" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" xml:space="preserve" xmlns:serif="http://www.serif.com/" style="fill-rule:evenodd;clip-rule:evenodd;stroke-linecap:round;stroke-linejoin:round;stroke-miterlimit:1.5;">
            <g transform="matrix(1,0,0,1,42.7602,80.7692)">
                <path [ngStyle]="{ 'stroke': color ? '#' + color.codeHexadecimal : 'white' }" d="M138.688,321.719L355.535,144.91L575.792,321.719" style="fill:none;stroke-width:25.15px;"/>
            </g>
        </svg>
    </div>
</div>