
import { Chapter, ChaptersResponse, SubChapter } from '@/models-queries/models';
import { FIND_CHAPTERS } from '@/models-queries/queries';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { BehaviorSubject } from 'rxjs';
import { I18nService } from './i18n.service';

@Injectable({ providedIn: 'root' })
export class ChaptersService
{
    // Evenement à s'abonner pour être informé du changement de chapitre et sous chapitre
    public get onChangeChapter(): BehaviorSubject<Chapter | undefined>
    {
        return this.selectedChapterBehavior;
    }
     // Evenement à s'abonner pour être informé du changement de chapitre et sous chapitre
     public get onChangeSubChapter(): BehaviorSubject<SubChapter | undefined>
     {
         return this.selectedSubChapterBehavior;
     }
    // Fourni le chapitre courant / chapitre sélectionné
    public get currentChapter(): Chapter | undefined
    {
        return this.selectedChapterBehavior.value;
    }
    
    private selectedChapterBehavior: BehaviorSubject<Chapter | undefined>;
    private selectedSubChapterBehavior: BehaviorSubject<SubChapter | undefined>;

    constructor(private apollo: Apollo, private i18nService: I18nService)
    {
        this.selectedChapterBehavior = new BehaviorSubject<Chapter | undefined>(undefined);
        this.selectedSubChapterBehavior = new BehaviorSubject<SubChapter | undefined>(undefined);
    }

    public async getChapters(): Promise<Chapter[]>
    {
        const response = await this.apollo.query<ChaptersResponse>(
        { 
            query: FIND_CHAPTERS,
            variables: {
                locale: this.i18nService.SuffixLocale
            }
        }).toPromise();
        return response.data.chapters;
    }

    public selectChapter(chapter: Chapter): void
    {
        this.selectedChapterBehavior.next(chapter);
    }

    public selectSubChapter(subChapter: SubChapter): void
    {
        this.selectedSubChapterBehavior.next(subChapter);
    }
}