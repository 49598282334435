
<div class="h-page overflow-y m-auto d-flex align-items-start align-items-sm-center">

    <!-- Chargement -->
    <div class="col text-center mt-5" *ngIf="!chapters">
        <div class="spinner-border" role="status"></div>
    </div>
    
    <!-- Liste des chapitres -->
    <div class="container" *ngIf="chapters">
        <div class="row justify-content-center pt-3 pt-sm-0 p-md-5 g-0">
            <div class="col-12 col-sm-6 col-xl-4 " *ngFor="let chapter of chapters; let i=index">

                <div class="chapter mx-4 mx-sm-2 my-2" (click)="onChapterClick(chapter)" [ngStyle]="{ 'border': '3px solid ' + (chapter.palette_color ? '#' + chapter.palette_color.codeHexadecimal : 'white') }">
                    <div class="ratio" style="--bs-aspect-ratio: 71%;">
                        <div class="d-flex flex-column align-items-start justify-content-around p-2 p-md-4">
                            <img class="icon-chapter" [src]="backend + chapter.icon.url" />
                            <div class="separator" [ngStyle]="{ 'background-color': chapter.palette_color ? '#' + chapter.palette_color.codeHexadecimal : 'white' }"></div>
                            <div class="typo-chapter-title" [ngStyle]="{ 'color': chapter.palette_color ? '#' + chapter.palette_color.codeHexadecimal : 'white' }">{{ chapter.title }}</div>
                        </div>
                    </div>
                </div>
        
            </div>
        </div>
    </div>

</div>

<!--
<div class="h-page overflow-y px-4 d-flex">

    <div class="d-flex flex-wrap justify-content-center m-auto">

        <!-- Chargement --
        <div class="col text-center" *ngIf="!chapters">
            <div class="spinner-border" role="status"></div>
        </div>
    
        <!-- Liste des chapitres --
        <ng-container *ngFor="let chapter of chapters; let i=index">
    
            <div class="chapter mx-2 my-2 ratio" style="--bs-aspect-ratio: 71%;" (click)="onChapterClick(chapter)">
                <div [ngStyle]="{ 'background-color': chapter.palette_color ? '#' + chapter.palette_color.codeHexadecimal : 'white' }" class="d-flex flex-column align-items-center justify-content-center text-center px-3 typo-chapter-title h-100">
                    {{ chapter.title }}
                </div>
            </div>
    
        </ng-container>
    </div>

</div>
-->