
<!-- Chargement -->
<div class="page d-flex align-items-center justify-content-center h-100" *ngIf="!page">
    <div class="spinner-border" role="status">
        <span class="visually-hidden">Loading...</span>
    </div>
</div>

<!-- Page composée de template -->
<div class="page" *ngIf="page">
    
    <div class="" *ngFor="let template of page.templates; let i=index" [ngClass]="{ 'bg-color-20': (i % 2) === 0, 'bg-color-21': (i % 2) !== 0 }">
        
        <!-- Template Card -->
        <template-card *ngIf="templateAsTemplateCard(template) as templateCard"
            [cardTitle]="templateCard.cardTitle"
            [background]="templateCard.background"
            [cardElements]="templateCard.cardElements"
            (onElementClicked)="onCardElementClicked($event)">
        </template-card>
        
        <!-- Template Carousel -->
        <template-carousel *ngIf="templateAsTemplateCarousel(template) as templateCarousel"
            [carouselTitle]="templateCarousel.carouselTitle"
            [background]="templateCarousel.background"
            [carouselElements]="templateCarousel.carouselElements"
            (onElementClicked)="onCarouselElementClicked($event)">
        </template-carousel>

        <!-- Template Grid -->
        <template-grid *ngIf="templateAsTemplateGrid(template) as templateGrid"
            [background]="templateGrid.background"
            [gridElements]="templateGrid.gridElements"
            (onElementClicked)="onGridElementClicked($event)">
        </template-grid>

        <!-- Template Title -->
        <template-title *ngIf="templateAsTemplateTitle(template) as templateTitle" 
            [background]="templateTitle.background"
            [title]="templateTitle.title"
            [subTitle]="templateTitle.subTitle">
        </template-title>

        <!-- Template Video -->
        <template-video *ngIf="templateAsTemplateVideo(template) as templateVideo"
            [background]="templateVideo.background"
            [title]="templateVideo.title"
            [description]="templateVideo.description"
            [videoUrl]="templateVideo.videoUrl">
        </template-video>

    </div>

</div>
