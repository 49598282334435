
import gql from "graphql-tag";

export const FIND_LANGUAGES = gql`
query Languages($locale: String!)
{
    languages(locale: $locale)
    {
        id_name
        name
    }
}`;

export const FIND_GENERAL = gql`
query General($locale: String!)
{
    general(locale: $locale)
    {
        calendarMedia { url }
    }
}`;

export const FIND_CHAPTERS = gql`
query Chapters($locale: String!)
{
    chapters(locale: $locale, sort: "chapterNumber:asc")
    {
        id
        title
        chapterNumber
        icon { url }
        palette_color { codeHexadecimal }

        subChapters
        {
            id
            title
            page
            {
                id
            }
        }

        users_permissions_role
        {
            id
            name
        }
    }
}`;

export const FIND_PAGE = gql`
query Page($pageId: ID!)
{
    page(id: $pageId)
    {
        id
        title
        templates
        {
            __typename ... on ComponentTemplateCardTemplateCard
            {
                background
                {
                    backgroundColor { codeHexadecimal }
                    backgroundImage { url }
                }
                cardTitle
                cardElements
                {
                    id
                    title
                    description
                    image { url }
                    zoomedImage { url }
                    resource { url }
                }
            }
            
            __typename ... on ComponentTemplateCarouselTemplateCarousel
            {
                background
                {
                    backgroundColor { codeHexadecimal }
                    backgroundImage { url }
                }
                carouselTitle
                carouselElements
                {
                    id
                    title
                    description
                    image { url }
                    resource { url }
                }
            }
            
            __typename ... on ComponentTemplateGridTemplateGrid
            {
                background
                {
                    backgroundColor { codeHexadecimal }
                    backgroundImage { url }
                }
                gridElements
                {
                    id
                    title
                    description
                    image { url }
                    resource { url }
                    grid_tag
                    {
                        id
                        title
                        order
                    }
                }
            }
            
            __typename ... on ComponentTemplateTitleTemplateTitle
            {
                background
                {
                    backgroundColor { codeHexadecimal }
                    backgroundImage { url }
                }
                title
                subTitle
            }
            
            __typename ... on ComponentTemplateVideoTemplateVideo
            {
                background
                {
                    backgroundColor { codeHexadecimal }
                    backgroundImage { url }
                }
                videoTitle
                title
                description
                videoUrl
            }
        }
    }
}`;

export const FIND_THERAPEUTIC_AREAS = gql`
query TherapeuticAreas($locale: String!)
{
    therapeuticAreas(locale: $locale)
    {
        id
        name
        paletteColor { codeHexadecimal }
    }
}`;

export const FIND_CALENDAR_TYPES = gql`
query CalendarTypes($locale: String!)
{
    calendarTypes(locale: $locale)
    {
        id
        name
    }
}`;

export const FIND_CALENDAR_REGIONS = gql`
query CalendarRegions($locale: String!)
{
    calendarRegions(locale: $locale)
    {
        id
        name
    }
}`;

export const GET_CALENDAR_EVENTS = gql`
query CalendarEvents($locale: String!)
{
    calendarEvents(locale: $locale)
    {
        name
        description
        eventDate
        updated_at

        therapeutic_area
        {
            id
            name
            paletteColor { codeHexadecimal }
        }
        calendar_type
        {
            id
            name
        }
        calendar_region
        {
            id
            name
        }
    }
}`;

export const FIND_LETTER_DIRECTOR = gql`
query Languages($locale: String!)
{
    letterDirector(locale: $locale)
    {
        directorLetter
        directorLetterMedia { url }
        updated_at
    }
}`;

export const FIND_VARIOUS_DOCUMENT = gql`
query Languages($locale: String!)
{
    variousDocument(locale: $locale)
    {
        variousDocuments
        {
            name
            resource { url }
        }
        updated_at
    }
}`;

export const CREATE_NOTE = gql`
mutation Createnote($uid: ID!, $description: String!)
{
    createNote(input: { data:
    {
        description: $description
        users_permissions_user: $uid
    }})
    {
        note
        {
            id
            created_at
            description
        }
    }
}`;

export const FIND_NOTE = gql`
query Notes($uid: ID!)
{
    notes(where: { 
        users_permissions_user: $uid
    }, sort: "created_at:desc")
    {
        id
        created_at
        description
    }
}`;

export const UPDATE_NOTE = gql`
mutation UpdateNote($noteId: ID!, $description: String!)
{
    updateNote(input: { 
        data: { description: $description },
        where: { id: $noteId }
    })
    {
        note
        {
            id
            created_at
            description
        }
    }
}`;

export const DELETE_NOTE = gql`
mutation DeleteNote($noteId: ID!)
{
    deleteNote(input: {
        where: { id: $noteId }
    })
    {
        note
        {
            id
        }
    }
}`;
