import { Injectable } from '@angular/core';

import { FIND_LANGUAGES } from '@/models-queries/queries';
import { Apollo } from 'apollo-angular';
import { LanguageText, LanguageTexts } from '@/models-queries/models';
import { BehaviorSubject } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class I18nService
{
    public onLanguageChanged: BehaviorSubject<string>;

    public get SuffixLocale(): string
    {
        return this.suffixLocale;
    }

    public texts : LanguageText[] | undefined;
    private suffixLocale: string;

    constructor(private apollo: Apollo)
    {
        this.suffixLocale = window.navigator.language.slice(0, 2);
        this.onLanguageChanged = new BehaviorSubject<string>(this.suffixLocale);
    }

    public async loadTexts(overrideLg: string = ''): Promise<LanguageText[]>
    {
        if (overrideLg !== '') this.suffixLocale = overrideLg;

        const response = await this.apollo.query<LanguageTexts>({ query: FIND_LANGUAGES, variables: { locale: this.suffixLocale }}).toPromise();
        this.texts = response.data.languages;
        return response.data.languages;
    }

    public changeLanguage(newLanguage: string = ''): void
    {
        var language : string = newLanguage.toLowerCase();
        if (language !== "fr" && language !== "en" && language !== "de")
        {
            language = "fr";
        }

        this.apollo.query<LanguageTexts>({ query: FIND_LANGUAGES, variables: { locale: language }}).subscribe((response) =>
        { 
            this.suffixLocale = language;
            this.texts = response.data.languages;
            this.onLanguageChanged.next(language);
        });
    }
    
    public getText(textIdName: string)
    {
        if (this.texts)
        {
            let text = this.texts.find(x => x.id_name === textIdName);
            if (text === undefined) return textIdName + " not found";
            return text.name;
        }
        return 'I18n_not_loaded';
    }
}
