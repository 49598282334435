
<div *ngIf="chapter" class="container">

    <!-- Titre -->
    <div class="row">

        <div class="col-12">
            
            <div class="d-flex align-items-center mt-4 mb-4 mx-4 mx-sm-2">
                <img class="icon-chapter" [src]="backend + chapter.icon.url" />
                <div class="typo-header-chapter ms-4">{{ chapter.title }}</div>
            </div>

        </div>
    </div>

    <!-- Liste des chapitres -->
    <div class="row">

        <div class="col-12 col-sm-6 col-md-4" *ngFor="let subChapter of chapter.subChapters; let i=index" (click)="onSubChapterClick(subChapter)">
            <div class="chapter mx-4 mx-sm-2 my-3" [ngStyle]="{ 'background-color': '#' + getChapterColor(i) }">
                <div class="ratio"  style="--bs-aspect-ratio: 71%;">
                    
                    <div class="d-flex flex-column align-items-start justify-content-around p-2 p-md-4">
                        <div class=""></div>
                        <div class="separator"></div>
                        <div class="typo-chapter-title">{{ subChapter.title }}</div>
                        <div class="typo-error text-center my-2" *ngIf="!subChapter.page">{{ 'PAGE_MISSING' | translate }}</div>
                    </div>

                </div> 
            </div>
        </div>

    </div>

</div>