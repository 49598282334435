import { Chapter } from '@/models-queries/models';
import { Route } from '@/models-queries/route';
import { AuthenticationService } from '@/services/authentication.service';
import { ChaptersService } from '@/services/chapters.service';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-chapters',
  templateUrl: './chapters.component.html',
  styleUrls: ['./chapters.component.scss']
})
export class ChaptersComponent implements OnInit
{
    backend: string = environment.backEndUrl;
    
    chapters: Chapter[] | undefined;

    constructor(private router: Router, private chaptersService: ChaptersService, private authService: AuthenticationService)
    {
    }

    ngOnInit(): void
    {
        this.chaptersService.getChapters().then(chapters =>
        {
            this.chapters = chapters.filter(chapt => chapt.users_permissions_role.name === this.authService.CurrentUserRole);
        });
    }

    onChapterClick(chapter: Chapter)
    {
        this.chaptersService.selectChapter(chapter);
        this.router.navigate([Route.Chapter]);
    }
}
