import { Chapter, SubChapter } from '@/models-queries/models';
import { Route } from '@/models-queries/route';
import { ChaptersService } from '@/services/chapters.service';
import { Component, ElementRef, HostListener, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'hamburger',
  templateUrl: './hamburger.component.html',
  styleUrls: ['./hamburger.component.scss']
})
export class HamburgerComponent implements OnInit
{    
    chapter: Chapter | undefined;
    
    isOpen: boolean = false;

    @HostListener('document:click', ['$event'])
    clickout(event: any)
    {
        // Clique en dehors du composant ferme le composant
        if (!this.eRef.nativeElement.contains(event.target)) this.isOpen = false;
    }

    constructor(private router: Router, private chaptersService: ChaptersService, private eRef: ElementRef)
    {
    }

    ngOnInit(): void
    {
        // S'abonne au change
        this.chaptersService.onChangeChapter.subscribe(chapter => this.chapter = chapter);
    }

    onHamburgerClick(): void
    {
        this.isOpen = !this.isOpen;
    }

    onSubChapterClick(subChapter: SubChapter): void
    {
        if (subChapter.page)
        {
            // Force Angular à reload quand on vient d'une même url
            this.router.navigateByUrl('/', {skipLocationChange: true}).then(()=>
            {
                this.isOpen = false;
                this.router.navigate([Route.Page, subChapter.page.id]);
            });
        }
    }
}
