import { Media, Modal, Note } from '@/models-queries/models';
import { GeneralService } from '@/services/general.service';
import { ModalService } from '@/services/modal.service';
import { Component, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'modal-note',
  templateUrl: './modal-note.component.html',
  styleUrls: ['./modal-note.component.scss']
})
export class ModalNoteComponent implements OnInit
{
    notes: Array<Note>;

    notesModal: Modal | undefined;

    private modalSubscription: Subscription | undefined;

    isAddingNote: boolean = false;
    newNote: Note;
    
    constructor(private generalService: GeneralService, private modalService: ModalService)
    {
        this.notes = new Array<Note>();
        this.newNote = { id: -1, description: '', isEditing: true, isDeleting: false, created_at: new Date() };
    }

    ngOnInit(): void
    {
        this.modalSubscription = this.modalService.onNotesModalChange().subscribe(modalData => 
        {
            this.isAddingNote = false;

            this.notesModal = modalData;
            this.refreshModal();
        });
    }

    ngOnDestroy(): void
    {
        if (this.modalSubscription) this.modalSubscription.unsubscribe();
    }

    refreshModal(): void
    {
        this.generalService.getNotes().then(notes =>
        {
            if (notes)
            {
                this.notes = notes;
                //this.directorLetterMedia = letter.directorLetterMedia;
            }
        });
    }
    
    onAddClick(): void
    {
        this.newNote = { id: -1, description: '', isEditing: true, isDeleting: false, created_at: new Date() };
        this.isAddingNote = true;
    }

    onSaveNewNoteClick(): void
    {
        this.generalService.createNote(this.newNote).then(newNote =>
        {
            if (newNote)
            {
                this.isAddingNote = false;
                this.refreshModal();
            }
        });
    }

    onCancelNewNoteClick(): void
    {
        this.isAddingNote = false;
    }

    onSaveClick(note: Note): void
    {
        this.generalService.updateNote(note).then(updatedNote => 
        {
            if (updatedNote)
            {
                note.isEditing = false;
                note = updatedNote;
            }
        });
    }

    onEditClick(note: Note): void
    {
        note.isEditing = true;
    }

    onConfirmDeleteClick(note: Note): void
    {
        this.generalService.deleteNote(note).then(deletedNote =>
        {
            if (deletedNote)
            {
                console.log("deted: "+ deletedNote.id);
                this.notes = this.notes.filter(n => n.id !== deletedNote.id);
            }
        });
    }

    onCancelDeleteClick(note: Note): void
    {
        note.isDeleting = false;
    }

    onDeleteClick(note: Note): void
    {
        note.isDeleting = true;
    }

    onCloseClick(): void
    {
        this.modalService.closeModalNotes();
    }
}
