import { CalendarService } from '@/services/calendar.service';
import { GeneralService } from '@/services/general.service';
import { ModalService } from '@/services/modal.service';
import { Component, ElementRef, HostListener, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { Subscription, timer } from 'rxjs';
import { switchMap } from 'rxjs/operators';

@Component({
  selector: 'notification',
  templateUrl: './notification.component.html',
  styleUrls: ['./notification.component.scss']
})
export class NotificationComponent implements OnInit, OnDestroy
{
    private notifSubscription: Subscription | undefined;

    hasNewLetterDirector: boolean = false;
    hasNewCalendarEvent: boolean = false;
    hasNewVariousDoc: boolean = false;

    notificationNumber: number = 0;
    isOpen: boolean = false;

    @HostListener('document:click', ['$event'])
    clickout(event: any)
    {
        // Clique en dehors du composant ferme le composant
        if (!this.eRef.nativeElement.contains(event.target)) this.isOpen = false;
    }

    constructor(private calenderService: CalendarService, private generalService: GeneralService, private modalService: ModalService, private eRef: ElementRef)
    {
    }

    ngOnInit(): void
    {
        // Appel tout les Xs le service general pour savoir si un élément a été mis à jour
        this.notifSubscription = timer(0, environment.notificationRefreshTime).subscribe(general =>
        {
            this.notificationNumber = 0;
            this.hasNewLetterDirector = false;
            this.hasNewCalendarEvent = false;
            this.hasNewVariousDoc = false;

            let yesterday = new Date();
            yesterday.setDate(yesterday.getDate() - 1);

            // Notification lettre
            this.generalService.getLetterDirector().then(letter =>
            {
                if (letter)
                {
                    this.hasNewLetterDirector = new Date(letter.updated_at) > yesterday;
                    if (this.hasNewLetterDirector) this.notificationNumber++;
                }
            });

            // Notification calendrier
            this.calenderService.getCalendarEvents().then(calendEvents =>
            {
                calendEvents.forEach(event =>
                {
                    this.hasNewCalendarEvent = new Date(event.updated_at) > yesterday;
                    if (this.hasNewCalendarEvent)
                    {
                        return;     // Sort de la boucle si une notification de calendrier est trouvée
                    }
                });
                if (this.hasNewCalendarEvent) this.notificationNumber++;
            });

            // Notification documents divers
            this.generalService.getVariousDocuments().then(variousDocs =>
            {
                this.hasNewVariousDoc = new Date(variousDocs.updated_at) > yesterday && variousDocs.variousDocuments.length > 0;
                if (this.hasNewVariousDoc) this.notificationNumber++;
            });
        });
    }

    ngOnDestroy(): void
    {
        // Important se désabonne aux appels quand le composant est détruit
        this.notifSubscription?.unsubscribe();
    }

    onNotificationsClick(): void
    {
        this.isOpen = !this.isOpen;
    }

    onNotifLetterClick(): void
    {
        this.modalService.openModalLetter();
        this.isOpen = false;
    }
    onNotifCalendarClick(): void
    {
        this.modalService.openModalCalendar();
        this.isOpen = false;
    }
    onNotifVariousDocClick(): void
    {
        this.modalService.openVariousDocuments();
        this.isOpen = false;
    }
}
