
<div *ngIf="calendarModal" class="modal position-fixed align-items-center justify-content-center" [ngClass]="{ 'active': calendarModal.isModalOpen }">

    <div class="mod-dialog">

        <!-- Entête-->
        <div class="mod-header bg-color-4 d-flex flex-row align-items-center justify-content-between typo-title">
            
            <form class="px-2" [formGroup]="yearListForm">
                <select class="typo-select-year year-select p-1" (change)="onChangeYear($event)" formControlName="yearControl">
                    <option class="typo-modal-header-year-small" value="" disabled>{{ 'CHOOSE_YEAR' | translate }}</option>
                    <option *ngFor="let year of yearList" [ngValue]="year">{{ formatYear(year) }} </option>
                </select>
            </form>

            <div class="d-none d-sm-block d-flex align-items-center">
                <img class="icon-medium" src="assets/pictos/Calendar.svg" />
                <span class="ms-3 typo-modal-header">{{ calendarModal.header }}</span>
            </div>
            <div class="d-flex align-items-center">
                <btn-resource *ngIf="calendarMedia" [useDownload]="true" [resourceUrl]="calendarMedia.url"></btn-resource>
                <img class="mod-close mx-3" src="assets/pictos/CloseBlue.svg" (click)="onCloseClick()"/>
            </div>
        </div>
        
        <!-- Corps -->
        <div class="mod-body d-flex flex-row position-relative">

            <!-- Calendrier -->
            <div class="container-fluid overflow-x">
                <div class="row d-flex align-items-center flex-nowrap py-3">
                    <div class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3 typo-cell-header text-center" *ngIf="monthsSelected[9].selected">{{ 'OCTOBER' | translate }}<br>{{ yearSelected }}</div>
                    <div class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3 typo-cell-header text-center" *ngIf="monthsSelected[10].selected">{{ 'NOVEMBER' | translate }}<br>{{ yearSelected }}</div>
                    <div class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3 typo-cell-header text-center" *ngIf="monthsSelected[11].selected">{{ 'DECEMBER' | translate }}<br>{{ yearSelected }}</div>
                    <div class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3 typo-cell-header text-center" *ngIf="monthsSelected[0].selected">{{ 'JANUARY' | translate }}<br>{{ (yearSelected + 1) }}</div>
                    <div class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3 typo-cell-header text-center" *ngIf="monthsSelected[1].selected">{{ 'FEBRUARY' | translate }}<br>{{ (yearSelected + 1) }}</div>
                    <div class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3 typo-cell-header text-center" *ngIf="monthsSelected[2].selected">{{ 'MARCH' | translate }}<br>{{ (yearSelected + 1) }}</div>
                    <div class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3 typo-cell-header text-center" *ngIf="monthsSelected[3].selected">{{ 'APRIL' | translate }}<br>{{ (yearSelected + 1) }}</div>
                    <div class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3 typo-cell-header text-center" *ngIf="monthsSelected[4].selected">{{ 'MAY' | translate }}<br>{{ (yearSelected + 1) }}</div>
                    <div class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3 typo-cell-header text-center" *ngIf="monthsSelected[5].selected">{{ 'JUNE' | translate }}<br>{{ (yearSelected + 1) }}</div>
                    <div class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3 typo-cell-header text-center" *ngIf="monthsSelected[6].selected">{{ 'JULY' | translate }}<br>{{ (yearSelected + 1) }}</div>
                    <div class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3 typo-cell-header text-center" *ngIf="monthsSelected[7].selected">{{ 'AUGUST' | translate }}<br>{{ (yearSelected + 1) }}</div>
                    <div class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3 typo-cell-header text-center" *ngIf="monthsSelected[8].selected">{{ 'SEPTEMBER' | translate }}<br>{{ (yearSelected + 1) }}</div>
                </div>
                <div class="row d-flex flex-nowrap">
                    
                    <div *ngIf="monthsSelected[9].selected" class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3">
                        <ng-container *ngFor="let event of calendarEventsFiltered">
                            <calendar-cell *ngIf="getEventMonth(event) === 9" [color]="event.therapeutic_area.paletteColor" [header]="event.name" [content]="event.description" [eventDate]="event.eventDate" [region]="event.calendar_region ? event.calendar_region.name : ''"></calendar-cell>
                        </ng-container>
                    </div>
                    <div *ngIf="monthsSelected[10].selected" class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3">
                        <ng-container *ngFor="let event of calendarEventsFiltered">
                            <calendar-cell *ngIf="getEventMonth(event) === 10" [color]="event.therapeutic_area.paletteColor" [header]="event.name" [content]="event.description" [eventDate]="event.eventDate" [region]="event.calendar_region ? event.calendar_region.name : ''"></calendar-cell>
                        </ng-container>
                    </div>
                    <div *ngIf="monthsSelected[11].selected" class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3">
                        <ng-container *ngFor="let event of calendarEventsFiltered">
                            <calendar-cell *ngIf="getEventMonth(event) === 11" [color]="event.therapeutic_area.paletteColor" [header]="event.name" [content]="event.description" [eventDate]="event.eventDate" [region]="event.calendar_region ? event.calendar_region.name : ''"></calendar-cell>
                        </ng-container>
                    </div>
                    <div *ngIf="monthsSelected[0].selected" class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3">
                        <ng-container *ngFor="let event of calendarEventsFiltered">
                            <calendar-cell *ngIf="getEventMonth(event) === 0" [color]="event.therapeutic_area.paletteColor" [header]="event.name" [content]="event.description" [eventDate]="event.eventDate" [region]="event.calendar_region ? event.calendar_region.name : ''"></calendar-cell>
                        </ng-container>
                    </div>
                    <div *ngIf="monthsSelected[1].selected" class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3">
                        <ng-container *ngFor="let event of calendarEventsFiltered">
                            <calendar-cell *ngIf="getEventMonth(event) === 1" [color]="event.therapeutic_area.paletteColor" [header]="event.name" [content]="event.description" [eventDate]="event.eventDate" [region]="event.calendar_region ? event.calendar_region.name : ''"></calendar-cell>
                        </ng-container>
                    </div>
                    <div *ngIf="monthsSelected[2].selected" class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3">
                        <ng-container *ngFor="let event of calendarEventsFiltered">
                            <calendar-cell *ngIf="getEventMonth(event) === 2" [color]="event.therapeutic_area.paletteColor" [header]="event.name" [content]="event.description" [eventDate]="event.eventDate" [region]="event.calendar_region ? event.calendar_region.name : ''"></calendar-cell>
                        </ng-container>
                    </div>
                    <div *ngIf="monthsSelected[3].selected" class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3">
                        <ng-container *ngFor="let event of calendarEventsFiltered">
                            <calendar-cell *ngIf="getEventMonth(event) === 3" [color]="event.therapeutic_area.paletteColor" [header]="event.name" [content]="event.description" [eventDate]="event.eventDate" [region]="event.calendar_region ? event.calendar_region.name : ''"></calendar-cell>
                        </ng-container>
                    </div>
                    <div *ngIf="monthsSelected[4].selected" class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3">
                        <ng-container *ngFor="let event of calendarEventsFiltered">
                            <calendar-cell *ngIf="getEventMonth(event) === 4" [color]="event.therapeutic_area.paletteColor" [header]="event.name" [content]="event.description" [eventDate]="event.eventDate" [region]="event.calendar_region ? event.calendar_region.name : ''"></calendar-cell>
                        </ng-container>
                    </div>
                    <div *ngIf="monthsSelected[5].selected" class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3">
                        <ng-container *ngFor="let event of calendarEventsFiltered">
                            <calendar-cell *ngIf="getEventMonth(event) === 5" [color]="event.therapeutic_area.paletteColor" [header]="event.name" [content]="event.description" [eventDate]="event.eventDate" [region]="event.calendar_region ? event.calendar_region.name : ''"></calendar-cell>
                        </ng-container>
                    </div>
                    <div *ngIf="monthsSelected[6].selected" class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3">
                        <ng-container *ngFor="let event of calendarEventsFiltered">
                            <calendar-cell *ngIf="getEventMonth(event) === 6" [color]="event.therapeutic_area.paletteColor" [header]="event.name" [content]="event.description" [eventDate]="event.eventDate" [region]="event.calendar_region ? event.calendar_region.name : ''"></calendar-cell>
                        </ng-container>
                    </div>
                    <div *ngIf="monthsSelected[7].selected" class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3">
                        <ng-container *ngFor="let event of calendarEventsFiltered">
                            <calendar-cell *ngIf="getEventMonth(event) === 7" [color]="event.therapeutic_area.paletteColor" [header]="event.name" [content]="event.description" [eventDate]="event.eventDate" [region]="event.calendar_region ? event.calendar_region.name : ''"></calendar-cell>
                        </ng-container>
                    </div>
                    <div *ngIf="monthsSelected[8].selected" class="col-12 col-sm-4 col-lg-2 g-0 px-5 px-sm-3">
                        <ng-container *ngFor="let event of calendarEventsFiltered">
                            <calendar-cell *ngIf="getEventMonth(event) === 8" [color]="event.therapeutic_area.paletteColor" [header]="event.name" [content]="event.description" [eventDate]="event.eventDate" [region]="event.calendar_region ? event.calendar_region.name : ''"></calendar-cell>
                        </ng-container>
                    </div>
                </div>

            </div>
            
            <!-- Bouton filtre-->
            <div class="position-absolute filter-btn bg-color-5 d-flex justify-content-end align-items-end p-3" (click)="onToggleFilter()">
                <img src="assets/pictos/Filter.svg" class="calendar-icon" />
                <span class="typo-filter-title ms-2">{{ 'FILTER' | translate }}</span>
            </div>
            
            <!-- Filters -->
            <div class="position-absolute d-flex filter h-100 flex-column p-2 bg-color-5 overflow-y" [ngClass]="{ 'active': isFilterOpen }">
                
                <!-- Bouton filtre-->
                <div class="d-flex justify-content-center justify-content-sm-end my-2" (click)="onToggleFilter()">
                    <img src="assets/pictos/Filter.svg" class="calendar-icon" />
                    <span class="typo-filter-title ms-2">{{ 'FILTER' | translate }}</span>
                </div>

                <!-- Aire thérapeutiques -->
                <div class="">
                    <button class="accordion-button my-1" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseOne" aria-expanded="true" aria-controls="panelsStayOpen-collapseOne">
                        <img src="assets/images/Aire_Therapeutique.svg" class="calendar-icon" />
                        <span class="typo-filter-title ms-2">{{ 'THERAPEUTIC_AREAS' | translate }}</span>
                    </button>
                    <div id="panelsStayOpen-collapseOne" class="accordion-collapse collapse show" aria-labelledby="panelsStayOpen-headingOne">
                        
                        <div class="d-flex flex-wrap my-3">
                            <div *ngFor="let area of therapeuticAreasSelected; let i=index" class="therapeutic-area d-flex justify-content-center align-items-center text-center typo-therapeutic-areas p-1"
                                [ngStyle]="{
                                    'background-color': area.selected ? (area.therapeuticArea.paletteColor ? '#' + area.therapeuticArea.paletteColor.codeHexadecimal : 'white'): 'gray',
                                    'color': area.therapeuticArea.paletteColor ? 'white' : 'gray' }"
                                (click)="onSelectArea(area)">
                                {{ area.therapeuticArea.name }}
                            </div>
                        </div>
                        <!--
                        <separator color="#033878"></separator>
                        -->

                    </div>
                </div>

                <!-- Régions -->
                <div class="">
                    <button class="accordion-button my-1" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseTwo" aria-expanded="true" aria-controls="panelsStayOpen-collapseTwo">
                        <img src="assets/pictos/Region.svg" class="calendar-icon" />
                        <span class="typo-filter-title ms-2">{{ 'REGIONS' | translate }}</span>
                    </button>
                    <div id="panelsStayOpen-collapseTwo" class="accordion-collapse collapse show px-2" aria-labelledby="panelsStayOpen-headingOne">
                        
                        <div class="w-100 my-3">
                            <input type="checkbox" id="rall" [checked]="isAllRegionSelected()" (click)="onSelectAllRegions()" />
                            <label class="typo-types d-flex align-items-center " for="rall">{{ 'SELECTALL_CHECKBOX' | translate }}</label>
                        </div>

                        <div class="d-flex flex-wrap my-3">
                            <div *ngFor="let region of calendarRegionsSelected; let i=index" class="w-50 my-1">
                                <input type="checkbox" [id]="'r_' + i" [checked]="region.selected" (click)="onSelectRegion(region)" />
                                <label class="typo-types d-flex align-items-center " [for]="'r_' + i">{{ region.calendarRegion.name }}</label>
                            </div>
                        </div>

                    </div>
                </div>

                
                <!-- Types -->
                <div class="">
                    <button class="accordion-button my-1" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseThree" aria-expanded="true" aria-controls="panelsStayOpen-collapseThree">
                        <img src="assets/images/Type.svg" class="calendar-icon" />
                        <span class="typo-filter-title ms-2">{{ 'TYPES' | translate }}</span>
                    </button>
                    <div id="panelsStayOpen-collapseThree" class="accordion-collapse collapse show px-2" aria-labelledby="panelsStayOpen-headingOne">
                        
                        <div class="w-100 my-3">
                            <input type="checkbox" id="tall" [checked]="isAllTypeSelected()" (click)="onSelectAllTypes()" />
                            <label class="typo-types d-flex align-items-center " for="tall">{{ 'SELECTALL_CHECKBOX' | translate }}</label>
                        </div>

                        <div class="d-flex flex-wrap my-3">
                            <div *ngFor="let type of calendarTypesSelected; let i=index" class="w-50 my-1">
                                <input type="checkbox" [id]="'t_' + i" [checked]="type.selected" (click)="onSelectType(type)" />
                                <label class="typo-types d-flex align-items-center" [for]="'t_' + i">{{ type.calendarType.name }}</label>
                            </div>
                        </div>

                    </div>
                </div>

                <!-- Mois -->
                <div class="">
                    <button class="accordion-button my-1" type="button" data-bs-toggle="collapse" data-bs-target="#panelsStayOpen-collapseFour" aria-expanded="true" aria-controls="panelsStayOpen-collapseFour">
                        <img src="assets/images/Calendar_Filter.svg" class="calendar-icon" />
                        <span class="typo-filter-title ms-2">{{ 'MONTHS' | translate }}</span>
                    </button>
                    <div id="panelsStayOpen-collapseFour" class="accordion-collapse collapse show px-2" aria-labelledby="panelsStayOpen-headingOne">
                        
                        <div class="w-100 my-3">
                            <input type="checkbox" id="mall" [checked]="isAllMonthsSelected()" (click)="onSelectAllMonths()" />
                            <label class="typo-types d-flex align-items-center " for="mall">{{ 'SELECTALL_CHECKBOX' | translate }}</label>
                        </div>

                        <div class="d-flex flex-wrap my-3">
                            <div *ngFor="let month of monthsSelected; let i=index" class="w-50 my-1">
                                <input type="checkbox" [id]="'m_' + i" [checked]="month.selected" (click)="onSelectMonth(month)" />
                                <label class="typo-months d-flex align-items-center" [for]="'m_' + i">{{ month.monthName }}</label>
                            </div>
                        </div>

                    </div>
                </div>


            </div>

        </div>

    </div>
</div>