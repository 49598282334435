import { CardModal } from '@/models-queries/models';
import { ModalService } from '@/services/modal.service';
import { DOCUMENT } from '@angular/common';
import { Component, Inject, OnDestroy, OnInit } from '@angular/core';
import { environment } from 'environments/environment';
import { Subscription } from 'rxjs';

@Component({
  selector: 'modal-card',
  templateUrl: './modal-card.component.html',
  styleUrls: ['./modal-card.component.scss']
})
export class ModalCardComponent implements OnInit, OnDestroy
{
    backendUrl: string = environment.backEndUrl;
    
    cardModal: CardModal | undefined;

    private modalSubscription: Subscription | undefined;
    
    constructor(private modalService: ModalService, @Inject(DOCUMENT) private document: Document)
    {
    }

    ngOnInit(): void
    {
        this.modalSubscription = this.modalService.onCardModalChange().subscribe(modalData =>
        {
            // Evite le scroll du body en mode modal
            if (modalData.isModalOpen)  this.document.body.classList.add("modal-open");
            else                        this.document.body.classList.remove("modal-open");

            this.cardModal = modalData;
        });
    }

    ngOnDestroy(): void
    {
        if (this.modalSubscription) this.modalSubscription.unsubscribe();
    }

    onNoteClick(): void
    {
        this.modalService.openNotes();
    }
    
    onCloseClick(): void
    {
        this.modalService.closeModalCard();
    }
}
