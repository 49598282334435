
<div class="position-relative">
    <div class="chip typo-chip position-absolute top-0 end-0 d-flex align-items-center justify-content-center text-center">{{ notificationNumber }}</div>
    <img src="assets/images/Notification.svg" (click)="onNotificationsClick()" />

    <div class="notifs position-absolute end-0 bottom-0 flex-column bg-color-5" [ngClass]="{ 'active': isOpen }">
            
        <div *ngIf="hasNewLetterDirector" class="notif-row d-flex align-items-center justify-content-between mx-3 my-1" (click)="onNotifLetterClick()">
            <img class="icon-medium" src="assets/images/Plume.svg" />
            <div class="">{{ 'DIRECTOR_LETTER' | translate }}</div>
            <img class="icon-medium" src="assets/images/New.svg" />
        </div>
        <div *ngIf="hasNewCalendarEvent" class="notif-row d-flex align-items-center justify-content-between mx-3 my-1" (click)="onNotifCalendarClick()">
            <img class="icon-medium" src="assets/images/Calendar.svg" />
            <div class="">{{ 'CALENDAR' | translate }}</div>
            <img class="icon-medium" src="assets/images/New.svg" />
        </div>
        <div *ngIf="hasNewVariousDoc" class="notif-row d-flex align-items-center justify-content-between mx-3 my-1" (click)="onNotifVariousDocClick()">
            <img class="icon-medium" src="assets/images/Divers.svg" />
            <div class="">{{ 'VARIOUS_DOCUMENTS' | translate }}</div>
            <img class="icon-medium" src="assets/images/New.svg" />
        </div>

    </div>
</div>
