

<div class="container-fluid position-relative t-template">
    <!--
    <div class="bg-template-image position-absolute w-100 h-100 top-0 start-0" [ngStyle]=" {
            'background-image': background && background.backgroundImage ? 'url(' + backendUrl + background.backgroundImage.url + ')' : 'none', 
            'background-color': background && background.backgroundColor ? '#' + background.backgroundColor.codeHexadecimal : 'transparent'
            }">
    </div>
    -->
    <ng-content></ng-content>
</div>
