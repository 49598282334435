import { Background, CardElement } from '@/models-queries/models';
import { ModalService } from '@/services/modal.service';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { environment } from 'environments/environment';

@Component({
  selector: 'template-card',
  templateUrl: './template-card.component.html',
  styleUrls: ['./template-card.component.scss']
})
export class TemplateCardComponent implements OnInit
{
    @Input() cardTitle: string = '';
    @Input() background: Background | undefined;
    @Input() cardElements: CardElement[] = [];
    
    @Output() onElementClicked: EventEmitter<CardElement> = new EventEmitter<CardElement>();

    backendUrl: string = environment.backEndUrl;
    
    currentElementId: number = 0;
    currentCard: CardElement | undefined;

    constructor(private modalService: ModalService)
    {
    }

    ngOnInit(): void
    {
    }

    onPrevClick(): void
    {
        this.currentElementId--;
        if (this.currentElementId < 0)
        {
            this.currentElementId = this.cardElements.length - 1;
        }
    }

    onNextClick(): void
    {
        this.currentElementId++;
        if (this.currentElementId > this.cardElements.length - 1)
        {
            this.currentElementId = 0;
        }
    }

    onElementClick(elem: CardElement): void
    {
        this.onElementClicked.emit(elem);
    }

    onImageClick(elem: CardElement): void
    {
        console.log(elem);
        if (elem.zoomedImage)
        {
            this.modalService.openImageZoom(elem.zoomedImage.url);
        }
        else
        {
            this.modalService.openImageZoom(elem.image.url);
        }
    }
}
