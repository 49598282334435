
import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { Route } from './models-queries/route';
import { AuthenticationComponent } from './pages/authentication/authentication.component';
import { ChapterComponent } from './pages/chapter/chapter.component';
import { ChaptersComponent } from './pages/chapters/chapters.component';
import { PageComponent } from './pages/page/page.component';

const routes: Routes = [
    
    { path: '' , redirectTo: Route.Authentication, pathMatch: 'full' },
    
    { path: Route.Authentication, component: AuthenticationComponent },
    { path: Route.Chapters, component: ChaptersComponent },
    { path: Route.Chapter, component: ChapterComponent },
    { path: Route.Page + "/:id", component: PageComponent },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
