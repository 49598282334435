
export class Modal
{
    isModalOpen: boolean;
    header: string;

    constructor(opn: boolean, hdr: string)
    {
        this.isModalOpen = opn;
        this.header = hdr;
    }
}

export class CardModal extends Modal
{
    title: string;
    description: string;
    image: Media | null;
    resource: Media | null;

    constructor(opn: boolean, hdr: string, tit: string, des: string, img: Media | null, rsc: Media | null)
    {
        super(opn, hdr);
        
        this.title = tit;
        this.description = des;
        this.image = img;
        this.resource = rsc;
    }
}

export class ImageZoomModal extends Modal
{
    imageUrl: string;

    constructor(opn: boolean, hdr: string, imgUrl: string)
    {
        super(opn, hdr);

        this.imageUrl = imgUrl;
    }
}

export interface GeneralResponse
{
    general: General;
}

export interface General
{
    calendarMedia: Media;
}

export interface LetterDirectorResponse
{
    letterDirector: LetterDirector;
}

export interface LetterDirector
{
    directorLetter: string;
    directorLetterMedia: Media;
    updated_at: Date;
}

export interface VariousDocumentsResponse
{
    variousDocument: VariousDocument;
}

export interface VariousDocument
{
    updated_at: Date;
    variousDocuments: Document[];
}

export interface CreateNoteResponse
{
    createNote: NoteResponse;
}
export interface UpdateNoteResponse
{
    updateNote: NoteResponse;
}
export interface DeleteNoteResponse
{
    deleteNote: NoteResponse;
}
export interface NoteResponse
{
    note: Note;
}

export interface NotesResponse
{
    notes: Note[];
}

export interface Note
{
    id: number;
    created_at: Date;
    description: string;
    isEditing: boolean;
    isDeleting: boolean;
}

export interface Document
{
    name: string;
    resource: Media;
}

export interface ChaptersResponse
{
    chapters: Chapter[];
}
export interface ChapterResponse
{
    chapter: Chapter;
}
export interface PageResponse
{
    page: Page;
}

export interface TherapeuticAreaResponse
{
    therapeuticAreas: TherapeuticArea[];
}

export interface CalendarTypeResponse
{
    calendarTypes: CalendarType[];
}

export interface TherapeuticAreaSelected
{
    selected: boolean;
    therapeuticArea: TherapeuticArea;
}

export interface TherapeuticArea
{
    id: number;
    name: string;
    icon: Media;
    paletteColor: PaletteColor;
}

export interface CalendarEventResponse
{
    calendarEvents: CalendarEvent[];
}
export interface CalendarRegionResponse
{
    calendarRegions: CalendarRegion[];
}

export interface CalendarEventGroupByArea
{
    therapeuticArea: string;
    calendarEvents: CalendarEvent[];
}

export interface CalendarRegion
{
    id: number;
    name: string;
}

export interface CalendarEvent
{
    name: string;
    description: string;
    eventDate: Date;
    updated_at: Date;

    therapeutic_area: TherapeuticArea;
    calendar_type: CalendarType;
    calendar_region: CalendarRegion;
}

export interface PaletteColor
{
    name: string;
    codeHexadecimal: string;
}

export interface CalendarTypeSelected
{
    selected: boolean;
    calendarType: CalendarType;
}
export interface CalendarRegionSelected
{
    selected: boolean;
    calendarRegion: CalendarRegion;
}

export interface MonthSelected
{
    selected: boolean;
    monthNumber: number;
    monthName: string;
}

export interface CalendarType
{
    id: number;
    name: string;
}

export interface UsersPermissionsRole
{
    id: number;
    name: string; 
}

export interface Chapter
{
    id: number;
    title: string;
    chapterNumber: number;
    icon: Media;
    palette_color: PaletteColor;

    subChapters: SubChapter[];

    users_permissions_role: UsersPermissionsRole;
}

export interface SubChapter
{
    id: number;
    title: string;

    page: Page;
}

export interface Page
{
    id: number;
    title: string;

    templates: Template[];
}

export interface CardElement
{
    title: string;
    description: string;
    image: Media;
    zoomedImage: Media;
    resource: Media;
}

export interface CarouselElement
{
    title: string;
    description: string;
    image: Media;
    resource: Media;
}

export interface GridElement
{
    title: string;
    description: string;
    image: Media;
    resource: Media;
    grid_tag: GridTag;
}

export interface GridTag
{
    id: number;
    title: string;
    order: number;
}

export interface Background
{
    backgroundColor: BackgroundColor;
    backgroundImage: Media;
}

export interface BackgroundColor
{
    codeHexadecimal: string;
}

export interface Media
{
    updated_at: Date;
    url: string;
}

export type Template = TemplateCard | TemplateCarousel | TemplateGrid | TemplateTitle | TemplateVideo


export interface TemplateCard
{
    __typename: string;
    cardTitle: string;
    background: Background;
    cardElements: CardElement[];
}

export interface TemplateCarousel
{
    __typename: string;
    carouselTitle: string;
    background: Background;
    carouselElements: CarouselElement[];
}

export interface TemplateGrid
{
    __typename: string;
    background: Background;
    gridElements: GridElement[];
}

export interface TemplateTitle
{
    __typename: string;
    background: Background;
    title: string;
    subTitle: string;
}

export interface TemplateVideo
{
    __typename: string;
    background: Background;
    title: string;
    description: string;
    videoUrl: string;
}


export interface LanguageText
{
    id_name: string;
    name: string;
}

export interface LanguageTexts
{
    languages: LanguageText[];
}
