
import { CalendarEvent, CalendarEventResponse, CalendarRegion, CalendarRegionResponse, CalendarType, CalendarTypeResponse, Chapter, ChapterResponse, ChaptersResponse, TherapeuticArea, TherapeuticAreaResponse } from '@/models-queries/models';
import { FIND_CALENDAR_REGIONS, FIND_CALENDAR_TYPES, FIND_THERAPEUTIC_AREAS, GET_CALENDAR_EVENTS } from '@/models-queries/queries';
import { Injectable } from '@angular/core';
import { Apollo } from 'apollo-angular';
import { I18nService } from './i18n.service';

@Injectable({ providedIn: 'root' })
export class CalendarService
{
    constructor(private apollo: Apollo, private i18nService: I18nService)
    {
    }

    public async getCalendarTypes(): Promise<CalendarType[]>
    {
        const response = await this.apollo.query<CalendarTypeResponse>(
        { 
            query: FIND_CALENDAR_TYPES,
            variables: {
                locale: this.i18nService.SuffixLocale
            }
        }).toPromise();
        return response.data.calendarTypes;
    }

    public async getCalendarRegions(): Promise<CalendarRegion[]>
    {
        const response = await this.apollo.query<CalendarRegionResponse>(
        {
            query: FIND_CALENDAR_REGIONS,
            variables: {
                locale: this.i18nService.SuffixLocale
            }
        }).toPromise();
        return response.data.calendarRegions;
    }
    
    public async getTherapeuticAreas(): Promise<TherapeuticArea[]>
    {
        const response = await this.apollo.query<TherapeuticAreaResponse>(
        {
            query: FIND_THERAPEUTIC_AREAS,
            variables: {
                locale: this.i18nService.SuffixLocale
            }
        }).toPromise();
        return response.data.therapeuticAreas;
    }

    public async getCalendarEvents(): Promise<CalendarEvent[]>
    {
        const response = await this.apollo.query<CalendarEventResponse>(
        { 
            query: GET_CALENDAR_EVENTS,
            variables: {
                locale: this.i18nService.SuffixLocale
            }
        }).toPromise();
        return response.data.calendarEvents;
    }
}