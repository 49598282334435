import { CardElement, CarouselElement, GridElement, Page, TemplateCarousel, TemplateGrid, TemplateVideo } from '@/models-queries/models';
import { PagesService } from '@/services/pages.service';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Template, TemplateCard, TemplateTitle } from '@/models-queries/models';
import { ModalService } from '@/services/modal.service';
import { DownloaderService } from '@/services/downloader.service';
import { environment } from 'environments/environment';

@Component({
  selector: 'app-page',
  templateUrl: './page.component.html',
  styleUrls: ['./page.component.scss']
})
export class PageComponent implements OnInit
{
    templateAsTemplateCard(t: Template): TemplateCard | null
    {
        if (t.__typename === 'ComponentTemplateCardTemplateCard') return t as TemplateCard
        return null;
    };
    templateAsTemplateCarousel(t: Template): TemplateCarousel | null
    {
        if (t.__typename === 'ComponentTemplateCarouselTemplateCarousel') return t as TemplateCarousel
        return null;
    }
    templateAsTemplateGrid(t: Template): TemplateGrid | null
    {
        if (t.__typename === 'ComponentTemplateGridTemplateGrid') return t as TemplateGrid
        return null;
    }
    templateAsTemplateTitle(t: Template): TemplateTitle | null
    {
        if (t.__typename === 'ComponentTemplateTitleTemplateTitle') return t as TemplateTitle
        return null;
    };
    templateAsTemplateVideo(t: Template): TemplateVideo | null
    {
        if (t.__typename === 'ComponentTemplateVideoTemplateVideo')  return t as TemplateVideo
        return null;
    };

    backendUrl: string = environment.backEndUrl;

    page: Page | undefined;
    
    constructor(private route: ActivatedRoute, private pagesService: PagesService, private modalService: ModalService, private downloaderService: DownloaderService)
    {
    }

    ngOnInit(): void
    {
        const pageId = this.route.snapshot.params['id'];
        this.pagesService.getPage(pageId).then(page => this.page = page);
    }

    onCarouselElementClicked(carouselElem: CarouselElement): void
    {
        this.modalService.openModalCard(carouselElem.title, carouselElem.description, carouselElem.image, carouselElem.resource);
    }

    onCardElementClicked(cardElement: CardElement): void
    {
        this.downloaderService.download(this.backendUrl + cardElement.resource.url);
    }

    onGridElementClicked(gridElem: GridElement): void
    {
        this.modalService.openModalCard(gridElem.title, gridElem.description, gridElem.image, gridElem.resource);
    }
}
