// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment =
{
    production: false,

    LOCAL_STORAGE_CURRENT_USER: "lsServierUser",
    LOCAL_STORAGE_TOKEN: "lsServierToken",

    notificationRefreshTime: 999999999,

    backEndUrl: 'https://back-servier.itycom.ch',
    graphQLUrl: 'https://back-servier.itycom.ch/graphql',
    authenticationUrl: 'https://back-servier.itycom.ch/auth/local',
    //backEndUrl: 'http://128.65.199.152:1337',
    //graphQLUrl: 'http://128.65.199.152:1337/graphql',
    //authenticationUrl: 'http://128.65.199.152:1337/auth/local',
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
