
<template-background [background]="background" *ngIf="cardElements.length > 0">
    
    <div class="container">
        
        <!-- Titre produit -->
        <div *ngIf="cardTitle" class="row">
            <div class="offset-1 col-11">
                <div class="my-4 my-sm-5 typo-template-title">{{ cardTitle }}</div>
            </div>
        </div>

        <!-- Card -->
        <div class="row align-items-center pb-4 pb-sm-5">
            
            <div class="col-1 g-0 d-flex justify-content-end">
                <img *ngIf="currentElementId > 0" src="assets/pictos/ArrowLeftBlue.svg" (click)="onPrevClick()" class="arrow" />
            </div>
    
            <div class="col-10">
    
                <div class="card-element" *ngFor="let elem of cardElements; let i=index" [ngClass]="{ 'active': i === currentElementId }">

                    <div class="row p-3">
                        <div class="col-12 col-lg-6 d-flex justify-content-center align-items-center" *ngIf="elem.image">
                            <img class="mb-2 mb-lg-0 imgfitparent" [src]="backendUrl + elem.image.url" (click)="onImageClick(elem)" />
                        </div>
                        <div class="col-12 col-lg-6">
                            <div class="d-flex flex-column h-100">
                                <div class="mb-2 mb-lg-auto d-flex justify-content-end">
                                    <btn-resource *ngIf="elem.resource" [useDownload]="true" [resourceUrl]="elem.resource.url"></btn-resource>
                                </div>
                                <div class="typo-title text-color-1 mb-4">{{ elem.title }}</div>
                                <div class="mb-2 mb-lg-auto typo-description text-color-1 pre-line">{{ elem.description }}</div>
                            </div>
                        </div>
                    </div>
    
                </div>
    
            </div>
    
            <div class="col-1 g-0 d-flex justify-content-start">
                <img *ngIf="currentElementId < cardElements.length - 1" src="assets/pictos/ArrowRightBlue.svg" (click)="onNextClick()" class="arrow" />
            </div>
    
        </div>
    </div>

</template-background>


