import { CalendarEvent, CalendarRegionSelected, CalendarTypeSelected, Media, Modal, MonthSelected, TherapeuticArea, TherapeuticAreaSelected } from '@/models-queries/models';
import { CalendarService } from '@/services/calendar.service';
import { GeneralService } from '@/services/general.service';
import { I18nService } from '@/services/i18n.service';
import { ModalService } from '@/services/modal.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { Subscription } from 'rxjs';

@Component({
  selector: 'modal-calendar',
  templateUrl: './modal-calendar.component.html',
  styleUrls: ['./modal-calendar.component.scss']
})
export class ModalCalendarComponent implements OnInit, OnDestroy
{
    calendarModal: Modal | undefined;

    calendarEvents: CalendarEvent[] | undefined;
    yearList: number[] | undefined;
    yearListForm: FormGroup;

    therapeuticAreasSelected: TherapeuticAreaSelected[] | undefined;
    calendarTypesSelected: CalendarTypeSelected[] | undefined;
    calendarRegionsSelected: CalendarRegionSelected[] | undefined;
    monthsSelected: MonthSelected[];
    yearSelected: number;
    
    calendarEventsFiltered: CalendarEvent[] | undefined;

    calendarMedia: Media | undefined;

    isFilterOpen: boolean = false;

    private modalSubscription: Subscription | undefined;

    constructor(private formBuilder: FormBuilder, private i18nService: I18nService, private calendarService: CalendarService, private modalService: ModalService, private generalService: GeneralService)
    {
        this.monthsSelected = [
            { monthName: this.i18nService.getText("JANUARY"), monthNumber: 0, selected: true },
            { monthName: this.i18nService.getText("FEBRUARY"), monthNumber: 1, selected: true },
            { monthName: this.i18nService.getText("MARCH"), monthNumber: 2, selected: true },
            { monthName: this.i18nService.getText("APRIL"), monthNumber: 3, selected: true },
            { monthName: this.i18nService.getText("MAY"), monthNumber: 4, selected: true },
            { monthName: this.i18nService.getText("JUNE"), monthNumber: 5, selected: true },
            { monthName: this.i18nService.getText("JULY"), monthNumber: 6, selected: true },
            { monthName: this.i18nService.getText("AUGUST"), monthNumber: 7, selected: true },
            { monthName: this.i18nService.getText("SEPTEMBER"), monthNumber: 8, selected: true },
            { monthName: this.i18nService.getText("OCTOBER"), monthNumber: 9, selected: true },
            { monthName: this.i18nService.getText("NOVEMBER"), monthNumber: 10, selected: true },
            { monthName: this.i18nService.getText("DECEMBER"), monthNumber: 11, selected: true },
        ];

        this.yearSelected = new Date().getFullYear();   // Par défaut, sélectionne l'année courante

        this.yearListForm = this.formBuilder.group({
            yearControl: [this.yearSelected]
        });
    }

    ngOnInit(): void
    {
        this.modalSubscription = this.modalService.onCalendarModalChange().subscribe(modalData =>
        {
            this.calendarModal = modalData;
            this.refreshModal();
        });
    }
    
    ngOnDestroy(): void
    {
        if (this.modalSubscription) this.modalSubscription.unsubscribe();
    }

    refreshModal(): void
    {
        // Récupère les aires thérapeutiques et les converti dans un modèle sélectionnable
        this.calendarService.getTherapeuticAreas().then(therapAreas =>
        {
            this.therapeuticAreasSelected = [];
            therapAreas.forEach(therapArea => {
                let theraAreaSelected: TherapeuticAreaSelected = { selected: true, therapeuticArea: therapArea };
                this.therapeuticAreasSelected?.push(theraAreaSelected);
            });
        });
        
        // Récupère les types et les converti dans un modèle sélectionnable
        this.calendarService.getCalendarTypes().then(calendTypes =>
        {
            this.calendarTypesSelected = [];
            calendTypes.forEach(calendType => {
                let calendAreaSelected: CalendarTypeSelected = { selected: true, calendarType: calendType };
                this.calendarTypesSelected?.push(calendAreaSelected);
            });
        });

        // Récupère les régions et les converti dans un modèle sélectionnable
        this.calendarService.getCalendarRegions().then(calendRegions => 
        {
            this.calendarRegionsSelected = [];
            calendRegions.forEach(calendRegion => {
                let calendRegionSelected: CalendarRegionSelected = { selected: true, calendarRegion: calendRegion };
                this.calendarRegionsSelected?.push(calendRegionSelected);
            });
        });

        // Récupère les évènements du calendriers
        this.calendarService.getCalendarEvents().then(calendEvents =>
        {
            this.yearList = calendEvents.map(evt => new Date(evt.eventDate).getFullYear());     // Extrait les années de chaque évènments (contient donc des doublons)
            this.yearList = [...new Set(this.yearList)];                                        // Supprime les doublons des années

            this.calendarEvents = calendEvents;
            this.refreshFilter();
        });

        // Récupère le media téléchargeable
        this.generalService.getGeneral().then(general =>
        {
            if (general) this.calendarMedia = general.calendarMedia;
        });
    }

    private refreshFilter(): void
    {
        if (this.calendarEvents)
        {
            // Filtre les évènements du calendrier pour ne garder que ceux sélectionnés
            this.calendarEventsFiltered = this.calendarEvents.filter(event =>
            {
                if (this.calendarEvents && this.calendarTypesSelected && this.calendarRegionsSelected && this.therapeuticAreasSelected && event.calendar_type && event.calendar_region && event.therapeutic_area)
                {
                    const selectedTypeIds: number[] = this.calendarTypesSelected.filter(t => t.selected === true).map(t => t.calendarType.id);
                    const selectedRegionsIds: number[] = this.calendarRegionsSelected.filter(r => r.selected === true).map(r => r.calendarRegion.id);
                    const selectedAreaIds: number[] = this.therapeuticAreasSelected.filter(a => a.selected === true).map(a => a.therapeuticArea.id);
                    const selectedMonthIds: number[] = this.monthsSelected.filter(m => m.selected === true).map(m => m.monthNumber);

                    const dateEventMonth: number = new Date(event.eventDate).getMonth();
                    const dateEventYear: number = new Date(event.eventDate).getFullYear();

                    const hasSelectedType: boolean = selectedTypeIds.includes(event.calendar_type.id);
                    const hasSelectedRegion: boolean = selectedRegionsIds.includes(event.calendar_region.id);
                    const hasSelectedArea: boolean = selectedAreaIds.includes(event.therapeutic_area.id);
                    const hasSelectedMonth: boolean = selectedMonthIds.includes(dateEventMonth);
                    const isOctToDecOfThisYear: boolean = this.yearSelected === dateEventYear && dateEventMonth >= 9;
                    const isJanToSepOfNextYear: boolean = (this.yearSelected + 1) === dateEventYear && dateEventMonth < 9;

                    return hasSelectedType && hasSelectedRegion && hasSelectedArea && hasSelectedMonth && (isOctToDecOfThisYear || isJanToSepOfNextYear);
                }
                else
                {  
                    return false;
                }
            });

            this.calendarEventsFiltered.sort((d1, d2) => new Date(d1.eventDate).getTime() - new Date(d2.eventDate).getTime())
        }
    }

    onChangeYear(year:any): void
    {
        this.yearSelected = this.yearListForm.value.yearControl;
        this.refreshFilter();
    }

    onSelectArea(theraArea: TherapeuticAreaSelected): void
    {
        theraArea.selected = !theraArea.selected;
        this.refreshFilter();
    }

    onSelectType(calendarType: CalendarTypeSelected): void
    {
        calendarType.selected = !calendarType.selected;
        this.refreshFilter();
    }

    onSelectRegion(calendarRegion: CalendarRegionSelected): void
    {
        calendarRegion.selected = !calendarRegion.selected;
        this.refreshFilter();
    }

    onSelectMonth(month: MonthSelected): void
    {
        month.selected = !month.selected;
        this.refreshFilter();
    }

    getEventMonth(calendarEvent: CalendarEvent): number
    {
        return new Date(calendarEvent.eventDate).getMonth();
    }

    onToggleFilter(): void
    {
        this.isFilterOpen = !this.isFilterOpen;
    }
    
    onNoteClick(): void
    {
        this.modalService.openNotes();
    }
    
    onCloseClick(): void
    {
        this.modalService.closeModalCalendar();
    }

    formatYear(year: number): string
    {
        return this.i18nService.getText("OCTOBER").substring(0, 3) + year + " - " + this.i18nService.getText("SEPTEMBER").substring(0, 3) + (year+1);
    }

    groupByTherapeuticArea(calendarEvents: CalendarEvent[]): Map<TherapeuticArea, CalendarEvent[]>
    {
        // Groupe par aire thérapeutique
        let groupByArea: Map<TherapeuticArea, CalendarEvent[]> = new Map<TherapeuticArea, CalendarEvent[]>();
        calendarEvents.forEach(event =>
        {
            if (!groupByArea.has(event.therapeutic_area))
            {
                groupByArea.set(event.therapeutic_area, [event]);
            }
            else
            {
                groupByArea.get(event.therapeutic_area)?.push(event);
            }
        });
        return groupByArea;
    }

    isAllRegionSelected(): boolean
    {
        let allSelected: boolean = true;
        if (this.calendarRegionsSelected)
        {
            for (var i=0; i<this.calendarRegionsSelected.length; i++)
            {
                if (!this.calendarRegionsSelected[i].selected)
                {
                    allSelected = false;
                    break;
                }
            }
        }
        return allSelected;
    }
    onSelectAllRegions(): void
    {
        if (this.calendarRegionsSelected)
        {
            const isAllSelected: boolean = this.isAllRegionSelected();
            for (var i=0; i<this.calendarRegionsSelected.length; i++)
            {
                this.calendarRegionsSelected[i].selected = !isAllSelected;
            }
            this.refreshFilter();
        }
    }
    isAllTypeSelected(): boolean
    {
        let allSelected: boolean = true;
        if (this.calendarTypesSelected)
        {
            for (var i=0; i<this.calendarTypesSelected.length; i++)
            {
                if (!this.calendarTypesSelected[i].selected)
                {
                    allSelected = false;
                    break;
                }
            }
        }
        return allSelected;
    }
    onSelectAllTypes(): void
    {
        if (this.calendarTypesSelected)
        {
            const isAllSelected: boolean = this.isAllTypeSelected();
            for (var i=0; i<this.calendarTypesSelected.length; i++)
            {
                this.calendarTypesSelected[i].selected = !isAllSelected;
            }
            this.refreshFilter();
        }
    }
    isAllMonthsSelected(): boolean
    {
        let allSelected: boolean = true;
        if (this.monthsSelected)
        {
            for (var i=0; i<this.monthsSelected.length; i++)
            {
                if (!this.monthsSelected[i].selected)
                {
                    allSelected = false;
                    break;
                }
            }
        }
        return allSelected;
    }
    onSelectAllMonths(): void
    {
        if (this.monthsSelected)
        {
            const isAllSelected: boolean = this.isAllMonthsSelected();
            for (var i=0; i<this.monthsSelected.length; i++)
            {
                this.monthsSelected[i].selected = !isAllSelected;
            }
            this.refreshFilter();
        }
    }
}
