import { Media, Modal } from '@/models-queries/models';
import { GeneralService } from '@/services/general.service';
import { ModalService } from '@/services/modal.service';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'modal-letter',
  templateUrl: './modal-letter.component.html',
  styleUrls: ['./modal-letter.component.scss']
})
export class ModalLetterComponent implements OnInit, OnDestroy
{
    directorLetter: string | undefined;
    directorLetterMedia: Media | undefined;

    letterModal: Modal | undefined;

    private modalSubscription: Subscription | undefined;
    
    constructor(private generalService: GeneralService, private modalService: ModalService)
    {
    }

    ngOnInit(): void
    {
        this.modalSubscription = this.modalService.onLetterModalChange().subscribe(modalData => 
        {
            this.letterModal = modalData;
            this.refreshModal();
        });
    }

    ngOnDestroy(): void
    {
        if (this.modalSubscription) this.modalSubscription.unsubscribe();
    }

    refreshModal(): void
    {
        this.generalService.getLetterDirector().then(letter =>
        {
            if (letter)
            {
                this.directorLetter = letter.directorLetter;
                this.directorLetterMedia = letter.directorLetterMedia;
            }
        });
    }
    
    onNoteClick(): void
    {
        this.modalService.openNotes();
    }
    
    onCloseClick(): void
    {
        this.modalService.closeModalLetter();
    }
}
